import axios from 'axios';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import {Container,Row,Col,Form, Alert, Button} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {RegStyle}  from '../../CustomCSS/mainCSS'
export default function InstitutionForm() {
    const CONST_URL_SAVE="Api/InstitutionApi/SaveData";
    const CONST_URL_GET="Api/InstitutionApi/GetData";
    const cboStatus=useRef();
    const txtName=useRef();
    const txtContactNo=useRef();
    const txtContactPerson=useRef();
    const cboPermitValidation=useRef();
    const txtAddress=useRef();
    const {ID}=useParams();
    const navigate=useNavigate();
    const [disabledButton,setDisabledButton]=useState(false);
    const [stateError,setStateError]=useState(
        {
            showError: false,
            errorMessage:'',
            alertType:"danger",
            disableButton:false
        }
    )
    useEffect(()=>{
        if(ID>0){
            let data={
                insID:ID
            }
            axios({
                method:"post",
                url:CONST_URL_GET,
                data:data
            })
            .then(function(e){
             
                txtName.current.value=e.data[0].Name;
                txtContactNo.current.value=e.data[0].ContactNo;
                txtContactPerson.current.value=e.data[0].ContactPerson;
                txtAddress.current.value=e.data[0].Address;
                cboStatus.current.value=e.data[0].Status;
                cboPermitValidation.current.value=e.data[0].WithPermitValidation=="Y"?1:0;
            })
            .catch(function(ex){


            })
        }
    },[])
    const FormSubmit=(e)=>{
        e.preventDefault();
     
        setStateError({showError:false,errorMessage:"",alertType:"success"});
        if(txtName.current.value===""){
            setStateError({showError:true,errorMessage:"Please input institution name",alertType:"danger"}); 
        }else if(txtAddress.current.value===""){
            setStateError({showError:true,errorMessage:"Please input address.",alertType:"danger"});         
        }else{
            SaveForm();
        }
    
    }
    const SaveForm=()=>{
        let data={
            Name: txtName.current.value,
            ContactNo: txtContactNo.current.value,
            ContactPerson:txtContactPerson.current.value,
            Status:cboStatus.current.value,
            Address:txtAddress.current.value,
            WithPermitValidation:cboPermitValidation.current.value,
            insID:ID
        }
       setDisabledButton(true);
        axios({
           method:"post",
           data:data,
           url:CONST_URL_SAVE
        })
        .then(function(ex){
            if(ex.data=="SUCCESS"){
                setStateError({showError:true,errorMessage:"Record Saved",alertType:"success"});
            }else if(ex.data=="DUPLICATE"){
                setStateError({showError:true,errorMessage:"Name Already Exist",alertType:"danger"});
            }else{
                setStateError({showError:true,errorMessage:"Unable to save changes",alertType:"danger"});
            }
        })
        .catch(function(ex){
            setStateError({showError:true,errorMessage:"Unable to save changes : "+ ex,alertType:"danger"});
        })
        .finally(function(ex){
            setDisabledButton(false);
        })
    }
  
     const backToList=()=>{
        navigate("/Admin/Institution");

     }
  return (
    <>
    <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                    <h4>Add / Update Institution</h4>
                    <Divider/>
                    <Form.Group as={Row} className="mb-3" controlId="cboStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                            <Col sm="4">
                                <select class="form-select"  name="cboStatus" ref={cboStatus} >                                
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>                              
                                </select>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtName">
                        <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtName" ref={txtName} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtContactPerson">
                        <Form.Label column sm="2">With Permit Validation</Form.Label>
                        <Col sm="4">
                                <select class="form-select"  name="cboPermitValidation" ref={cboPermitValidation} >                                
                                <option value="1">With permit validation</option>
                                <option value="0">No permit validation</option>                              
                                </select>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtAddress">
                        <Form.Label column sm="2">Address</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtAddress" ref={txtAddress} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtContactNo">
                        <Form.Label column sm="2">Contact No.</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtContactNo" ref={txtContactNo} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtContactPerson">
                        <Form.Label column sm="2">Contact Person</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtContactPerson" ref={txtContactPerson} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                                <Alert show={stateError.showError} variant={stateError.alertType}>{stateError.errorMessage}</Alert>
                                
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                            <Button  style={{marginRight:"10px"}} type='button' onClick={backToList} variant='primary'>Back to Listing</Button>
                                <Button disabled={disabledButton} type='submit' variant='primary'>Save</Button>
                            </Col>
                        </Form.Group>
                </Form>
                 
            </Col>
        </Row>
        </Container>
        
    </>
  )
}
