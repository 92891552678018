import React, { useState } from 'react'
import Grid from '../../Component/Grid';
import { Button } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import Messagebox from '../../Component/Messagebox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function Institution() {
  const CONST_URL_GET_LIST="Api/InstitutionApi/GetGridList";
  const CONST_URL_GET_DEACTIVATE="Api/InstitutionApi/Deactivate";
  const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(false);
  const [refreshGridSource,setefreshGridSource]=useState(0);
  const [currentID,setCurrentID]=useState(0);
  const navigate =useNavigate();
  const ColumnList=[
      {FieldID:'insID', Label: 'Id'},
      {FieldID:'Status', Label: 'Status'},
      {FieldID:'Name', Label: 'Name'},
      {FieldID:'ContactPerson', Label: 'Contact Person'},
      {FieldID:'ContactNo', Label: 'Contact No'},
      {FieldID:'WithPermitValidation', Label: 'Permit Validation'}, 
      {FieldID:'Edit', Label: 'Edit'},
      {FieldID:'Delete', Label: 'Delete'}
         
  ]
  const statusFilterlist=[
      {id:"All",value:"All"},
      {id:"Active",value:"Active"},
      {id:"Inactive",value:"Inactive"},
      
    ]
  const AddNew=()=>{
      navigate("/Admin/InstitutionForm/0")
  }
  const ConfirmActionResultApprove=(e)=>{
     
    if(e==="Y"){
      let data={
        insID:currentID
      }
      axios({
        method:"post",
        url:CONST_URL_GET_DEACTIVATE,
        data:data

      })
      .then(function(ex){
        
        if(ex.data=="SUCCESS"){
          setefreshGridSource(prev=>prev+1);
        }

      })
      .catch(function(ex){

      })
    }
  }
  
  const onEdit=(e)=>{
    navigate("/Admin/InstitutionForm/"+e);
  }
  const onDelete=(e)=>{
    
    setCurrentID(e);
    setShowMessageConfirmApprove(prev=>prev+1);

  }
return (
  <>
  
   <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to deactivate?"} /> 
 
 <div style={{marginLeft:"15px",marginBottom:"10px",marginTop:"10px"}}>
     <h5>Institution List</h5>
<Divider />
  
 <Button variant="primary" size="sm" onClick={AddNew}  >
 Add New
 </Button>

  
 </div>
  <Grid 
   DataSource={CONST_URL_GET_LIST} 
   ColumnList={ColumnList} 
   uniqueID="insID" 
   onEdit={onEdit}       
   onDelete={onDelete}
   refreshGrid={refreshGridSource}       
   filter={statusFilterlist}
   LinkColumn="Name"
   DefaultStatus="All"
 />
  </>
)
}
