import React, { useState } from 'react'
import { Alert, Container } from 'react-bootstrap'
import ScheduleGrid from '../../Component/ScheduleGrid'
import ScheduleForm from '../../Component/ScheduleForm';
import Button from "react-bootstrap/Button";
import { Divider } from 'primereact/divider';

export default function Schedule() {
  const [refreshGrid,setRefreshGrid]=useState(0);
  const [editMode,setEditMode]=useState(0);
  const [editModeID,setEditModeID]=useState(0);
  const RefreshGrid=(e)=>(    
    
    setRefreshGrid((prev)=>prev+1)
  )
  const ShowForm=(id)=>{        
    setEditModeID(id);
    setEditMode((prev)=>prev+1);
  }
  return (
    <>    
    <div style={{marginLeft:"15px"}}>
    <h5 style={{marginTop:"10px"}}>Schedule List</h5>
    <Divider/>
    </div>
        <ScheduleForm onSave={RefreshGrid} onEdit={editMode} editModeID={editModeID}/>        
        <ScheduleGrid refreshGrid={refreshGrid} onEdit={ShowForm}    />
    
      
    </>
  )
}

