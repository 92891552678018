
import React, { useEffect, useState,useRef } from 'react'
import axios from 'axios';
import {  Form  } from 'react-bootstrap';
import TrainingCenterOptions from '../../Component/TrainingCenterOptions';
export default function SubmitPaymentStep1(props) {
  const CONST_URL ="Api/ScheduleApi/GetScheduleList";
  const [name,setName]=useState([{}]);
  const [venue,setVenue]=useState(0);
  
  var data={   
    CenterID:venue,
    Status:'Active'

  }

  
  useEffect(()=>{
   axios({
    url:CONST_URL,
    method:'post',
    data:data

   })
   .then(function(res){
    
      setName(res.data);
   })
   
  },[venue]);
  const ShowStep2=(SchedID)=>{
    
    props.ShowStep2(SchedID);
  
  }
  const selectedTrainingCenter=(e)=>{
    setVenue(e);

  }
  if(name){
  return (
    <>
    
    <div className='row'>
    <div className='col-sm-1'><label>Location</label></div>
      <div className='col-md-2'>
      <TrainingCenterOptions  setValue={selectedTrainingCenter}></TrainingCenterOptions>
      </div>
    </div>
    
    <div className='row'>
      
      
  
    { name.map((rs) =>
    <div className='col-lg-2'>
        <div class="card">
  
        <div className="card-body">
          <h5 className="card-title">{rs.TestTrainingName}</h5>
          <p className="card-text" style={{fontSize:"20px"}}><span style={{fontWeight:"600"}}>{rs.Remarks}</span>
          <hr></hr>
          <table style={{marginTop:"10px"}}>
              <tr>
                  <td style={{minWidth:"100px"}}>Date</td>
                  <td style={{width:"10px"}}>:</td>
                  <td>{rs.DateFrom}</td>
              </tr>
              <tr>
                  <td>Venue</td>
                  <td>:</td>
                  <td>{rs.CenterName}</td>
              </tr>
              <tr>
              <td>Price</td>
                  <td>:</td>
                  <td>1,600</td>
              </tr>
          </table>
          
          
          </p>
          <a href="#" class="btn btn-primary" style={{minWidth:"100px",float:"right"}} onClick={()=>ShowStep2(rs.SchedID)}>
          
            Select</a>
        </div>
      </div>
      </div>
      )}
  
  </div>    
    
    </>

    
  )
  }else{
    return "Loading..."
  }
  }

