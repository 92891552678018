import React, { useRef, useState } from 'react'
import Grid from '../../Component/Grid';
import GridPayment from '../../Component/GridPayment';
import axios from 'axios';
import { useContext } from 'react';
import { LoginContexts } from '../../Contexts/LoginContexts';
import PaymentSummary from '../../Component/PaymentSummary';
import Messagebox from '../../Component/Messagebox';
import MessageboxToast from '../../Component/MessageboxToast';
import ScheduleForm from '../../Component/ScheduleForm';
import { RegistrationCount } from '../../Component/RegistrationCount';
import RejectedModal from '../../Component/RejectedModal';
export default function AdminDashboard() {
  const CONST_URL_PAYMENTLIST ="Api/PaymentApi/GetPaymentList"
  const CONST_URL_APPROVAL ="Api/PaymentApi/ApprovalAction"
  const CONST_URL_SCHEDULE ="Api/ScheduleApi/GetScheduleListSummary"
  const {username}=useContext(LoginContexts);
  const [showSummary,setShowSummary]=useState(0);
  const [summaryPaymentID,setSummaryPaymentID]=useState(0);
  const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(0);
  const [messageboxHeader,setMessageboxHeader]=useState('')
  const [messageboxContent,setMessageboxContent]=useState('')
  const [action,setAction]=useState('');
  const [showToast,setShowToast]=useState(0);
  const [editScheduleForm,setEditScheduleForm]=useState(0);
  const [paymentID,setPaymentID]=useState(0);
  const [scheduleID,setScheduleID]=useState(0);
  const txtReason=useRef(null);
  const [showRejectModal,SetShowRejectModal]=useState(0);
  const [rejectReason,setRejectReason]=useState('');
  const refCboFilter=useRef(null);
  const [filterChangedValue,setFilterChangedValue]=useState('All');
  
  const ColumnListSchedule=[
    
    {FieldID:'DateFrom', Label: 'Date'},
    {FieldID:'CenterName', Label: 'Venue'},
    {FieldID:'Institution', Label: 'Institution'},        
    {FieldID:'TestTrainingName', Label: 'Test / Training'},            
    {FieldID:'MaxNo', Label: 'Max'},    
    {FieldID:'ReservedQty' ,Label: 'Reserved'},
    {FieldID:'AvailCount' ,Label: 'Available'}    
    
  ];
  const ColumnList=[
    {FieldID:'Status', Label: 'Status'},
    {FieldID:'TransDate', Label: 'Trans Date'},
    
    {FieldID:'ReferenceNo', Label: 'Ref. No'},
    {FieldID:'Name', Label: 'Name'},
    {FieldID:'ContactNo', Label: 'Contact No'},
    {FieldID:'Venue', Label: 'Venue'},
    {FieldID:'ScheduleDate', Label: 'Schedule'},
    {FieldID:'BankName', Label: 'Bank'},
    {FieldID:'DatePaid', Label: 'Paid'},
    {FieldID:'PaymentReferenceNo', Label: 'PaymentReferenceNo'},
    {FieldID:'Amt', Label: 'Amt'},    
           
    {FieldID:'Edit', Label: 'Approve'}, 
    {FieldID:'Rejected', Label: 'Rejected'} ,  
    {FieldID:'Delete', Label: 'Delete'}   
  ];
  const statusFilterlist=[
    {id:"All",value:"All"},
    {id:"Requested",value:"Requested"},
    {id:"Rejected",value:"Rejected"},
    {id:"Approved",value:"Approved"}
  ]
  
  const [approvalGrid,setApprovalGrid]=useState(0);
  const ApprovalAction=(id,action)=>{
    var data={
      Action:action,
      Id:id,
      UserID:username,
      Reason: rejectReason

    }
   // alert(CONST_URL_APPROVAL);
    axios({
      method:"post",
      url:CONST_URL_APPROVAL,
      data:data
    })
    .then(function(res){      
      if(res.data[0].ErrorMessage=="SUCCESS"){
        setApprovalGrid((prev)=>prev+1);     
       setShowToast((prev)=>prev+1);
      }

    })
    .catch(function(ex){
      alert("Unable process the request")
    })

  }
  const ApproveRequest=(e)=>{
    setAction("A");
    setPaymentID(e);
    setMessageboxHeader("Confirm Approval");
    setMessageboxContent("Are you sure you want to approve?")
    setShowMessageConfirmApprove((prev)=>prev+1);
   
  }
  const DeleteRequest=(e)=>{
    setAction("D");
    setPaymentID(e);
    setMessageboxHeader("Confirm deletion");
    setMessageboxContent("Are you sure you want to delete?")
    setShowMessageConfirmApprove((prev)=>prev+1);

  }
  const ProceedCancel=(e)=>{
    setRejectReason(e);
    alert(rejectReason);
    setAction("R")    
    ApprovalAction(paymentID,"R");
  }
  const RejectRequest=(e)=>{

    setPaymentID(e);
    showRejectMessagebox();
    
    
  }
  const showRejectMessagebox=()=>{
    SetShowRejectModal((prev)=>prev+1);

  }
  const LinkColumn=(e)=>{
    setSummaryPaymentID(e);
    setShowSummary((prev)=>prev+1);
  }
  const EditSchedule=(e)=>{
    setScheduleID(e);
    setEditScheduleForm((prev)=>prev+1);
  }  
  const ConfirmActionResultApprove=(e)=>{

    if(e=="Y"){
      ApprovalAction(paymentID,action)
    }
  }
  const RefreshScheduleGrid=()=>{
    setApprovalGrid((prev)=>prev+1);
  }
  const FilterButton=(e)=>{
    setFilterChangedValue(refCboFilter.current.value);
    setApprovalGrid((prev)=>prev+1);
  }
  return (
   <>

   <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={messageboxHeader} Content={messageboxContent} /> 
   <MessageboxToast ShowToast={showToast} />
   <ScheduleForm  HideAddButton={true} onSave={RefreshScheduleGrid}  onEdit={editScheduleForm} editModeID={scheduleID}  />
   <PaymentSummary showForm={showSummary} paymentID={summaryPaymentID}  />

   <RejectedModal ProceedCancel={ProceedCancel} showMessagebox={showRejectModal} ></RejectedModal>
  <div style={{marginTop:"20px",marginLeft:"10px"}}>

 
    <div className='row'>
    <div className='col-lg-6'>
    <h4 style={{color:"#28a745"}}>Current Schedule </h4>
    
    <Grid 
      DataSource={CONST_URL_SCHEDULE} 
      ColumnList={ColumnListSchedule} 
      uniqueID="SchedID" 
      onEdit={EditSchedule} 
      refreshGrid={approvalGrid}
      filter=''      
      DefaultStatus='Active'
    />

    </div>
    <div className='col-lg-6'>
    <h4 style={{color:"#28a745"}}>Summary</h4>
    
    <RegistrationCount showMessagebox={showRejectModal}></RegistrationCount>

    </div>
    </div>
   
  </div>
  <div style={{marginTop:"20px",marginLeft:"10px"}}>
    <h4 style={{color:"#28a745"}}>Payment</h4>
    
    <div className='row' style={{marginBottom:"10px",display:"none"}}>
            <div className='col-lg-2'>Reject Reason</div>
            <div className='col-lg-9'>
            <textarea class="form-control" id="txtRejReason" ref={txtReason} rows="3"></textarea>

            </div>
            
    </div>

  


   
  


    
    
    <GridPayment 
      DataSource={CONST_URL_PAYMENTLIST} 
      ColumnList={ColumnList} 
      uniqueID="PaymentID" 
      ShowAddButton={false}
      onEdit={ApproveRequest} 
      onDelete={DeleteRequest} 
      onReject={RejectRequest}
      onLinkColumn={LinkColumn}
      refreshGrid={approvalGrid}
      filter={statusFilterlist}
      LinkColumn="Name"
      DefaultStatus="All"
    />
  </div>

   </>
  )
}
