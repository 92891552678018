import React from 'react'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from '../Asset/Images/Logo/logo.png';
export default function NavbarPublic() {
  
  return (
   <>



     <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#"><img src={logo} style={{height:"40px"}} />PSY Systems & Innovations, OPC</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>           
            <Nav.Link href="/Schedule">Schedule</Nav.Link>    
            <Nav.Link href="/ContactUs">Contact Us</Nav.Link>       
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
   </>
  )
}
