import React from 'react'
import { Container } from 'react-bootstrap'
import { Divider } from 'primereact/divider'
import CustomerInfoEdit from '../../Component/CustomerInfoEdit'
import personalInfo from "../../Asset/Images/64/user.png";
export default function CustomerEditAccount() {
  return (
    <>
    <Container>   
    <div className='card'>           
        <div className='cardContent'>
            <div style={{marginTop:"20px",marginLeft:"10px"}}>
                <h4 style={{color:"#28a745"}}><img src={personalInfo} style={{height:"48px"}} /> Personal Information</h4>
                <Divider/>
            </div>
            <CustomerInfoEdit />
        </div>
    </div>
    </Container>
    </>
  )
}
