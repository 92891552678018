import React, { useEffect } from 'react'
import { ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
 
 

export default function Messagebox(props) {
    

    useEffect(()=>{
        
        if(props.showMessagebox>=1){
            confirmDialog({
                message:  props.Content,
                header: props.Header,
                icon: 'pi pi-exclamation-triangle',
                defaultFocus: 'accept',
                accept,
                reject
            });
    }
        
    },[props.showMessagebox])

    const accept = () => {   
        props.result("Y")
    };
    const reject = () => {
        props.result("N")
    };
   
  return (
    <>
    
    <ConfirmDialog />
    </>
  )
}
