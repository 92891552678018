import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function RejectedModal(props) {
    const txtReason=useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    useEffect(()=>{
        
        if(props.showMessagebox>=1){
            handleShow();
    }
        
    },[props.showMessagebox])
    const ProceedButton=()=>{

        props.ProceedCancel(txtReason.current.value);
        handleClose();
    }
  return (
    
    <>
 

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to reject?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <textarea class="form-control" id="txtRejReason" ref={txtReason} rows="3"></textarea>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={ProceedButton}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RejectedModal