import React from 'react'
import { Outlet } from 'react-router-dom'
import '../../CustomCSS/custom.css';
import NavbarCustomer from '../../Component/NavbarCustomer';

export default function CustomerLayout() {
  return (
    <>
    <NavbarCustomer />
    <Outlet></Outlet>
    </>
  )
}
