import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {  Form  } from 'react-bootstrap';
function TrainingCenterOptions(props) {
   
   
  const [defValue,setDefValue]=useState(0);
   const selectedChange=(data)=>{
     
    props.onSubmit(data.value);
   }
   
  
  const url="Api/TrainingCenterApi/GetList";
  const [centerList,setCenterList]=useState([]);
  const cboCenter=useRef('');

  useEffect(()=>{
    setDefValue(0);
     axios({
      url:url,
      method:'get'
     })
     .then(function(res){
      console.log(res.data);
        setCenterList(res.data);
     })
     setDefValue(props.setDefValue);
    },[defValue,props.setDefValue]);
    const setDefault=(x)=>{
      setDefValue(x);
      
    }
    const onChangeSelected=(x)=>{

        props.setValue(x);
    } 

 
 

  return (
    <>
    
  

   
  <Form.Select onChange={e=>onChangeSelected(e.target.value)}  aria-label="Default select example" defaultValue={defValue} className='Form-Control' ref={cboCenter}>
      
      <option value="0"></option>
    { centerList.map((rs) =>
        <option value={rs.value} selected={defValue==rs.value?'selected':''} key={rs.value}>{rs.label}</option>
      )}
  </Form.Select>

    
    </>
  )
}

export default TrainingCenterOptions