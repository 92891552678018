import React, { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { Divider } from 'primereact/divider';
import { Container,Row,Col,Form, Button, Alert } from 'react-bootstrap'
import { Column } from "primereact/column";
import { DataTable } from  "primereact/datatable"
import { PrimeReactProvider } from 'primereact/api';
import bankImage from "../Asset/Images/64/bank.png";
export default function BankList() {
 const CONST_URL= "api/BankApi/GetList";
  const [bankList,setBankList]=useState([]);
 useEffect(()=>{
    axios.get(CONST_URL)                       
    .then(function (res) {  
       // SetGridData(response.data);
       var data = res.data
      
       
       setBankList(data);
    })
    .catch(function (error) {                
        console.log("Error" +error);
    })
    .finally(function () {
        // always executed
    });

 },[])
   
 
  return (
    <>
   
    <div style={{margin:"10px",marginTop:"20px"}}>
    <h4 style={{color:"#28a745"}}><img src={bankImage} style={{height:"48.px"}} /> Please pay only to the following bank</h4>
    <Divider/>
    <PrimeReactProvider>
            <DataTable value={bankList} 
           
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            emptyMessage="No data found"
            className="datatable-responsive"
            currentPageReportTemplate="Showing {first} of {last} of {totalRecords}"
            rows={5}
            showGridlines
            stripedRows
             >                
                <Column field="BankName" sortable header="Bank"></Column>  
                <Column field="AccountNo" sortable header="Account No"></Column> 
                <Column field="AccountName" sortable header="Account Name"></Column>                          
            </DataTable>
            </PrimeReactProvider>
           
            </div>
        </>
 
     
  )
}
