import axios from 'axios';
import React, { useRef, useState,useEffect } from 'react'
import {  Form  } from 'react-bootstrap';
export default function DropdownInstitution(props) {
    const CONST_URL='Api/InstitutionApi/GetList';
    const [name,setName]=useState([{}]);
    const [defValue,setDefValue]=useState(0);
    const cboTitle=useRef();
    
    useEffect(()=>{
     axios({
      url:CONST_URL,
      method:'get'
     })
     .then(function(res){
      
        setName(res.data);
     })
     setDefValue(props.setDefValue);
    },[defValue,props.setDefValue]);
    const setDefault=(x)=>{
      setDefValue(x);
      
    }
    const onChangeSelected=(x)=>{
      
        props.setValue(x);
    }
    return (
      <>
      

      <Form.Select onChange={e=>onChangeSelected(e.target.value)}  aria-label="Default select example" setDefValue={defValue} className='Form-Control' ref={cboTitle}>
      
        <option value="0"></option>
      { name.map((rs) =>
          <option value={rs.insID} selected={defValue==rs.insID?'selected':''} key={rs.insID}>{rs.Name}</option>
        )}
    </Form.Select>
      
      
      </>
  
      
    )
  }
  