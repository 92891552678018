import React, { useEffect,useRef } from 'react'
import { Toast } from 'primereact/toast';
export default function MessageboxToast(props) {
  const toast = useRef(null);
  useEffect(()=>{
    if(props.ShowToast>=1){
      toast.current.show({ severity: 'success', summary: 'Completed', detail: 'We have successfully processed your request.', life: 3000 });
    }
  },[props.ShowToast])
  return (
    <Toast ref={toast} />
  )
}
