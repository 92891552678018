import React, { Outlet } from 'react-router-dom'
import { useContext } from 'react'
import { LoginContexts } from '../Contexts/LoginContexts'
import { Navigate } from 'react-router-dom';
const ProtectedRoute=()=> {
    const{username}=useContext(LoginContexts);

    
    return(
        
        username==''||username==null?<Navigate to='/' />:  <Outlet />
    )
}
export default ProtectedRoute;

