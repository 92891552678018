import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container,Row,Col,Form,Modal,Button } from 'react-bootstrap';

export default function PaymentSummary(props) {
    const CONST_URL_PAYMENT="Api/PaymentApi/GetPaymentByID";
    const [showModal,setShowModal]=useState(false);
    const [scheduleDate,setScheduleDate]=useState('');
    const [approvedRejectedDate,setApprovedRejectedDate]=useState('');
    const [approvedRejectedBy,setApprovedRejectedBy]=useState('');
    const [attachmentFileName,setAttachmentFileName]=useState('');
    useEffect(()=>{    
        if(props.paymentID>0){
            setShowModal(true);
        }
    },[props.showForm])
    useEffect(()=>{
        const data={
            PaymentID:props.paymentID
        }
        axios({
            url:CONST_URL_PAYMENT,
            method:"post",
            data:data
            }
        )
        .then(function(res){
            setScheduleDate(res.data[0].ScheduleDate);
            setApprovedRejectedDate(res.data[0].ApprovedRejectedDate);
            setApprovedRejectedBy(res.data[0].ApprovedRejectedBy);
            setAttachmentFileName(res.data[0].AttachmentFileName);
        })
        .catch(function(ex){

        })
    },[props.paymentID])
    const handleClose=()=>{
        setShowModal(false);
    }
    function OpenNewTab(fileName){
        var url=process.env.REACT_APP_BACKEND_SERVER_NAME + '/UploadedFiles/' + fileName;
        window.open(url, "_blank")
     }
  return (
    <>

     <Modal  size="md" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Container>
                <Row>
                    <Col>            
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Approved By</Form.Label>
                        <Col sm="6">
                        <Form.Control  type='text'  defaultValue={approvedRejectedBy}  ></Form.Control>
                        </Col>                                               
                    </Form.Group>   
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Approved Date</Form.Label>
                        <Col sm="6">
                        <Form.Control  type='text'  defaultValue={approvedRejectedDate}  ></Form.Control>
                        </Col>                                               
                    </Form.Group>  
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Attachment</Form.Label>
                        <Col sm="6">
                        <div style={{marginTop:"10px"}}>
                        <a href='#' onClick={()=>OpenNewTab(attachmentFileName)}>View Attachment</a>
                        </div>
                        </Col>                                               
                    </Form.Group>     
                    </Col>
                    </Row>
        </Container>       
     
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>        
        </Modal.Footer>
      </Modal>
    </>
  )
}
