import React, { useRef,useContext } from 'react'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ConfirmPopup,confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import logo from '../Asset/Images/Logo/logo.png';
import { useNavigate } from 'react-router-dom';
import { LoginContexts } from '../Contexts/LoginContexts';

export default function NavbarAdmin() {
  const {username}=useContext(LoginContexts);   
  const toast = useRef(null);
  const navigate=useNavigate();
  const accept = () => {
    localStorage.clear();
    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Thank you, have a nice day', life: 3000 });
    navigate('/')
  };
  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };
  const confirm1 = (event) => {

  confirmPopup({
    target: event.currentTarget,
    message: 'Are you sure you want to proceed?',
    icon: 'pi pi-exclamation-triangle',
    defaultFocus: 'accept',
    accept,
    reject
  });
};
  return (
   <>
   <Toast ref={toast} />
    <ConfirmPopup />
     <Navbar expand="lg" className="bg-body-tertiary">
    
       <Navbar.Brand href="#"   ><img src={logo} style={{height:"40px"}} /> PSY Systems & Innovations, OPC</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
          
          {username!=''?(
            <>
          <Nav.Link href="/Admin/AdminDashboard">Home</Nav.Link>
          <Nav.Link href="/Admin/Member">Member</Nav.Link>
          <Nav.Link href="/Admin/Schedule">Schedule</Nav.Link>            
          <NavDropdown title="Masterfile" id="basic-nav-dropdown">              
              <NavDropdown.Item href="/Admin/AdminAccount">Users</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/Admin/UploadMember">Upload Permit</NavDropdown.Item>
              <NavDropdown.Divider />            
              <NavDropdown.Item href="/Admin/ViewPermitList">View All Permit</NavDropdown.Item>
              <NavDropdown.Divider />            
              <NavDropdown.Item href="/Admin/ViewBankList">Bank Master</NavDropdown.Item>
              <NavDropdown.Divider />         
              <NavDropdown.Item href="/Admin/ViewTestTrainList">Testing / Trainings</NavDropdown.Item>
              <NavDropdown.Divider />         
              <NavDropdown.Item href="/Admin/Institution">Institution</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Reports" id="basic-nav-dropdown">              
              <NavDropdown.Item href="/Admin/Reports">Payment</NavDropdown.Item>    
              <NavDropdown.Item href="/Admin/ReportMasterlist">Masterlist</NavDropdown.Item>    
              <NavDropdown.Item href="/Admin/ReportsApprovalStatus">Approval Status Report</NavDropdown.Item>              
          </NavDropdown>
            
            <Nav.Link href="#" onClick={confirm1} >Log-Out</Nav.Link>   
          </>
          ):""}
       
            
       
       
          </Nav>
          
            
        </Navbar.Collapse>
      
    </Navbar>
   </>
  )
}
