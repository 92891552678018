import axios from 'axios';
import React, { useRef, useState } from 'react'
import {useNavigate} from   'react-router-dom'
import { useContext } from 'react';
import { LoginContexts } from '../../Contexts/LoginContexts';
import { Container,Form,Row,Col, Alert,Button } from 'react-bootstrap'
import AdminloginCard from '../../Component/AdminloginCard';
import PublicCard from '../../Component/PublicCard';
import { Divider } from 'primereact/divider';

const CONST_URL_LOGIN_NEW="Api/UserApi/AdminLogin"

export default function AdminLogin() {
const {setUsername,setUserID,setAccountType}=useContext(LoginContexts);
const refUsername=useRef();
const refPassword=useRef();
const[showAlert,setShowAlert]=useState(false);
const[alertType,setAlertType]=useState('danger');
const [errMessage,setErrMessage]=useState('');
const [buttonState,setButtonState]=useState(false);
const [buttonText,setButtonText]=useState('Login');
const {username}=useContext(LoginContexts);
  const navigate=useNavigate();
  const RegStyle={
    CardStyle:{
        marginTop: '50px'
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: "8px",
        boxShadow:"0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1)"

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }

  }
  function FormSubmit(e){
    e.preventDefault();
    
    setErrMessage('');
    setShowAlert(false);
    if(refUsername.current.value==''){
      setErrMessage('Pleaes input username');
      setShowAlert(true);
      setAlertType('danger');
    }else if(refPassword.current.value==''){
      setErrMessage('Pleaes input password');
      setShowAlert(true);
      setAlertType('danger');
    }else{
      CheckCredentials();
    }
    function CheckCredentials(){
      
      setButtonState(false);
      setButtonText("Processing");
      var data={
        UserName:refUsername.current.value,
        UserPassword:refPassword.current.value
      }
     
      axios({
        method: "post",
        url:CONST_URL_LOGIN_NEW,
        data:data
      })
      .then(function(response){
       
        if(response.data[0].ErrorMessage=="SUCCESS"){
          
          localStorage.setItem("username",refUsername.current.value);
          localStorage.setItem("userid",response.data[0].UserID);
          localStorage.setItem("accountType","Admin");
          setAccountType("Admin");
          //accountType
          //accountType,setAccountType
          setUsername(refUsername.current.value);
          setUserID(response.data[0].UserID);
          alert(username);
          navigate("/Admin/AdminDashboard");
        }else if(response.data[0].ErrorMessage=="INACTIVE"){
          setErrMessage("Your account is deactivated");
          setShowAlert(true);
          setAlertType('danger');
        }else{
          setErrMessage("Invalid Username / Password");
          setShowAlert(true);
          setAlertType('danger');

        }
      })
      .catch(function(ex){
        alert(ex);
        setErrMessage("Can't Login. Our system is currently on maintenance");
        setShowAlert(true);
        setAlertType('danger');
      })
      .finally(function(ex){
        setButtonText("Login");
      })
    }
  }
  return (
    <>

      <Container  >
        <Row>
        <Col xs>
        <PublicCard></PublicCard>
            </Col>
            <Col xs>
                <Form style={RegStyle.RegFormStyle}  onSubmit={FormSubmit}>
                    <h4>Administrator's Login</h4>
                    <Divider/>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label style={{fontWeight:"600px"}} column sm="4"  >Username</Form.Label>
                            <Col sm="8">
                                <Form.Control type="text" defaultValue="" ref={refUsername}  autoComplete='false' />
                            </Col>
                        </Form.Group>
                      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label style={{fontWeight:"600px"}} column sm="4"  >Password</Form.Label>
                            <Col sm="8">
                            <Form.Control   type="password" ref={refPassword}  defaultValue="" />
                            </Col>
                        </Form.Group>
                         
                       
                        
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12" style={{textAlign:'center'}}>
                                <Alert show={showAlert} variant={alertType}>{errMessage}</Alert>
                                
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12" style={{textAlign:'right'}}>
                                <Button type='submit' disabled={buttonState}  variant='primary'>{buttonText}</Button>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                        <Col sm="12" style={{textAlign:'left'}}>
                          <h4 style={{fontWeight:"600",textDecoration:"underline"}} >Forget Password?</h4>
                        </Col>
                        </Form.Group>

                        
                    </Form>        
                   
            </Col>
        </Row>
    </Container>
    
    </>
  )
}
