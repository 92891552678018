import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row,Form } from 'react-bootstrap'
import { useContext } from 'react'
import { LoginContexts } from '../../Contexts/LoginContexts'
import {Button} from  'react-bootstrap'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'



const SubmitPaymentStep2 =(props)=> {
  const [showErrorMessage,setShowErrorMessage]=useState(false);
  const [showErrorDisplay,setShowErrorDisplay]=useState('');
  const {username}=useContext(LoginContexts);
  const {userID}=useContext(LoginContexts);
  const [trainingCenter,setTrainingCenter]=useState(0);
  const CONST_URL="Api/ScheduleApi/GetScheduleByLocation";
  const CONST_URL_UPLOAD="Api/PaymentApi/SubmitPayment";
  const refScheduleDate=useRef('');
  const [selectedFile,setSelectedFile]=useState();
  const refBankName=useRef();
  const refDate=useRef();
  const refAmt=useRef();
  const refPaymentRef=useRef();
  const navigate=useNavigate();
  const [buttonText,setButtonText]=useState('Submit');
  const [disabledButton,setDisabledButton]=useState(false);
  const [dateSchedule,setDateSchedule]=useState([{}]);

  const [venue,setVenue]=useState('');
  const [testTrainingName,setTestTrainingName]=useState('');
  const [remarks,setRemarks]=useState('');
  const [dateFrom,setDateFrom]=useState('');
  useEffect(()=>{
    if(props.SelectedSchedID>0)
    {
      var data={
        SchedID:props.SelectedSchedID
      }
      axios({
        method: "post",
        url:"Api/ScheduleApi/GetSchedule",
        data: data
      })  
      .then(function(res){
        setVenue(res.data[0]["CenterName"]);
        setTestTrainingName(res.data[0]["TestTrainingName"]);
        setRemarks(res.data[0]["Remarks"]);
        setDateFrom(res.data[0]["DateFrom"]);
        //refScheduleDate.current.value=res.data[0].DateFrom;
      })


    }


    console.log(props.SelectedSchedID);
},[props.SelectedSchedID])

useEffect(()=>{
  if(trainingCenter>0){
    var data={
      CenterID:trainingCenter
    }
    axios({
      method: "post",
      url:CONST_URL,
      data: data
    })  
    .then(function(res){
      setDateSchedule(res.data);
      //refScheduleDate.current.value=res.data[0].DateFrom;
    })
      
  

    
  }

},[trainingCenter])



const selectedTrainingCenter=(data)=>{
  setTrainingCenter(data);  
}
const onFileChange=((event)=>{

  setSelectedFile(event.target.files[0]);
})
const SetError=(ErrMessage)=>{
  setShowErrorDisplay(ErrMessage)
  setShowErrorMessage(true)
}
function isValidEntry(){
  SetError("");
  
  if(username==''){
    SetError("Please input username.")    
    return false;
  }else if(refBankName.current.value==''){
    SetError("Please input bank name");
    refBankName.current.focus();   
    return false;   
  }else if (refPaymentRef.current.value==''){
    SetError("Please input payment reference No.");
    refPaymentRef.current.focus();   
    return false;  
  }else if (refDate.current.value==''){
    SetError("Kindly indicate which date you made your payment.");
    refDate.current.focus();   
    return false;  
  }else if(refAmt.current.value==0){
    SetError("Please input amount paid");     
    refAmt.current.focus();
    return false;  
  }else if(selectedFile==undefined){
    SetError("Please upload proof of payment");         
    return false;  
  }else if(parseInt(userID)==0){
    SetError("Invalid UserID");         
    return false;  
  }else{
    return true;
  }
  
}
const ShowStep1=()=>{
  props.ShowStep1();
}
const onSubmit=(()=>{
   // Create an object of formData   
   if(isValidEntry()==false){    
    return;
   }    
   setButtonText('Processing');
   setShowErrorMessage(false);
   setDisabledButton(true);

   const formData = new FormData();
 
   // Update the formData object
   formData.append(
       "UploadedImage",
       selectedFile,
       selectedFile.name
   );
   
   formData.append("userID",userID);
   formData.append("Username",username);
   formData.append("Bank",refBankName.current.value);
   formData.append("DatePaid",refDate.current.value);
   formData.append("PaymentRef",refPaymentRef.current.value);   
   formData.append("SchedID",props.SelectedSchedID);
   formData.append("Amt",refAmt.current.value);   
   
   axios.post(CONST_URL_UPLOAD, formData)
   .then(function(res){      
     
      if(res.data[0].ErrorMessage!="SUCCESS"){
        //alert(res.data[0].ErrorMessage);
        SetError(res.data[0].ErrorMessage); 
      }else{
        var RefNo=res.data[0].RefNo;
        
        localStorage.setItem("refno",RefNo);     
        
        navigate("/Customer/PaymentSuccess/1");
      }
   })
   .catch(function (error) {                
    alert("Error" +error);
  })
  .finally(function () {
    // always executed
    setButtonText('Submit');
    setDisabledButton(false);
  });
  


  
  

})

  return (
    <>
  


   
          

            <Form.Group as={Row} className="mb-3">              
              <Form.Label column sm="2">Test / Training</Form.Label>
                <Col sm="4">
                <Form.Label column>{testTrainingName}</Form.Label>
                </Col>             
            </Form.Group>
            <Form.Group as={Row} className="mb-3">              
              <Form.Label column sm="2">Remarks</Form.Label>
                <Col sm="4">
                <Form.Label column>{remarks}</Form.Label>
                </Col>             
            </Form.Group>
            <Form.Group as={Row} className="mb-3">              
              <Form.Label column sm="2">Date</Form.Label>
                <Col sm="4">
                <Form.Label column>{dateFrom}</Form.Label>
                </Col>             
            </Form.Group>
            <Form.Group as={Row} className="mb-3">              
              <Form.Label column sm="2">Venue</Form.Label>
                <Col sm="4">
                <Form.Label column>{venue}</Form.Label>
                </Col>             
            </Form.Group>


            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">              
              <Form.Label column sm="2">Bank</Form.Label>
                <Col sm="4">
                    <Form.Control type="text" defaultValue="" ref={refBankName}  autoComplete='off' />
                </Col>
             
             </Form.Group>
           
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2">Reference No</Form.Label>
                <Col sm="4">
                    <Form.Control type="text" defaultValue=""  ref={refPaymentRef} autoComplete='off' />
                </Col>
             </Form.Group>
            
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2">Date Paid</Form.Label>
                <Col sm="4">
                    <Form.Control type="date" defaultValue="0" ref={refDate}    autoComplete='off' />
                </Col>
             </Form.Group>
            
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2">Amount</Form.Label>
                <Col sm="4">
                    <Form.Control type="number" defaultValue="0" ref={refAmt}    autoComplete='off' />
                </Col>
             </Form.Group>
             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2">Upload Payment </Form.Label>
                <Col sm="4">
                    <Form.Control type="file" onChange={onFileChange}       />
                </Col>
             </Form.Group>

             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">             
                <Col sm="6">
                    <Alert show={showErrorMessage} variant='danger'>{showErrorDisplay}</Alert>
                </Col>
             </Form.Group>


             <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2"></Form.Label>
                <Col sm="4" style={{textAlign:"center"}}>
                <Button disabled={disabledButton} style={{minWidth:"100px"}}  onClick={onSubmit} variant="primary">{buttonText}</Button>
                <Button   onClick={ShowStep1} variant="primary" style={{minWidth:"100px",marginLeft:"10px"}}>Back</Button>
                   
                </Col>
             </Form.Group>

           
        
  
   
   </>
  )
}

export default SubmitPaymentStep2