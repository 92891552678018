import React from 'react'
import { Outlet } from 'react-router-dom'
import NavbarPublic from '../../Component/NavbarPublic'
import '../../CustomCSS/custom.css';
import { useLayoutEffect } from 'react';

export default function PublicLayout() {
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#f0f2f5"
  });
  return (
        <>
        <NavbarPublic />
        <Outlet/>
        </>
  )
}
