import React, { useContext, useEffect, useRef } from 'react'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button  from 'react-bootstrap/Button';
import { Container,Row,Col,Form } from 'react-bootstrap';
import TrainingCenterOptions from './TrainingCenterOptions';
import { Alert } from 'react-bootstrap';
import DropdownTestTrainingList from './DropdownTestTrainingList';
import axios from 'axios';
import DropdownInstitution from './DropdownInstitution';
import Instituttion from './Instituttion';
import { LoginContexts } from '../Contexts/LoginContexts';


export default function ScheduleForm(props) {
    const {username}=useContext(LoginContexts);  
  //  const handleShow = () => setShow(true);
    const txtRemarks=useRef('');
    
    const dteFrom=useRef('');
    
    
    const txtCapacity=useRef('');
    const dtCutOffDate=useRef('');
    const cboStatus=useRef('');
   
    const [trainCenter,setTrainCenter]=useState(0);
    const [institution,setInstitution]=useState(0);
    const [errorMessage,setErrorMessage]=useState('');
    const [showAlert,setShowAlert]=useState(false);
    const [testTraining,setTestTraining]=useState(0);
    const [show, setShow] = useState(false);
    const [trainingStatusValue,setTrainingStatusValue]=useState([]);
    const [schedID,setSchedID]=useState(0);
    const handleClose = () => setShow(false);
    const hideAddButton=props.HideAddButton;
   
    
    const ServerName=process.env.REACT_APP_BACKEND_SERVER_NAME;
    const url=ServerName+"/Api/ScheduleApi/SaveSchedule";
    const urlEdit=ServerName+"/Api/ScheduleApi/GetSchedule";
    const StatusList=[
      { label:'Active',value:'Active' },
      { label:'Closed',value:'Closed' },
      { label:'Inactive',value:'Inactive' }
    ]
     
    useEffect(() => {
      let schedID=props.editModeID;
      if(schedID>0)
      { 

        setErrorMessage("");
        setShowAlert(false);
        setSchedID(schedID);
        handleShowEdit();      

        
        axios({
          method:'post',
          url: urlEdit,
          data:{
            SchedID:schedID
          }
        })
        .then(function(response){         
        
           
          dteFrom.current.value=response.data[0].DateFrom;
         
          dtCutOffDate.current.value=response.data[0].CutOffDate;
          txtCapacity.current.value=response.data[0].MaxNo;
          cboStatus.current.value=response.data[0].Status;
          setInstitution(response.data[0].InsID);
          setTestTraining(response.data[0].TestTrainID);
          setTrainCenter(response.data[0].CenterID);

          
          txtRemarks.current.value=response.data[0].Remarks;
        //  setTrainingStatusValue();
        setTrainingStatusValue([{label:response.data[0].CenterName,value:response.data[0].CenterID}]);
       
           
        })
      }
    },[props.onEdit]);

    const selectedTrainingCenter=(data)=>{
     
      setTrainCenter(data);
    }
    const selectedTestTraining=(data)=>{
      
      setTestTraining(data);

    }
    const selectedInstitution=(data)=>{
      setInstitution(data);
    }
    function handleShowEdit(){
      setShow(true);
    }
    function handleShow(){
      setShow(true);
      setSchedID(0);
      //txtRemarks.current.value="";
      setInstitution("");
      setTestTraining("");
      setTrainCenter("");
      
    }
    function saveForm(){
      setErrorMessage('');
     
      const myData={     
        Status:cboStatus.current.value,
        SchedID:schedID,    
        DateFrom:dteFrom.current.value,
        TestTrainID:trainCenter,
        CenterID:trainCenter,
        MaxNo:parseInt(txtCapacity.current.value),
        InsID:institution,
        Remarks:txtRemarks.current.value,
        CutOffDate:dtCutOffDate.current.value,
        AddedBy:username
      }
 
      if(myData.Status==''){
        setErrorMessage("Please select status");
        setShowAlert(true);
        return;
      }else if(myData.InsID==0){
        setErrorMessage("Please select Institution");
        setShowAlert(true);
        return;    
      }else if(myData.TestTrainID==0){
        setErrorMessage("Please select test / training");
        setShowAlert(true);
        return;     
      }else if(myData.DateFrom==''){
        setErrorMessage("Please select date from");
        setShowAlert(true);
        return;     
      }else if(myData.Capacity==0){
        setErrorMessage("Please input capacity");
        setShowAlert(true);
        return;
      }else if(myData.TrainingCenter==0){
        setErrorMessage("Please select training venue");
        setShowAlert(true);
        return;
      }else if(myData.CutOffDate==''){
        setErrorMessage("Please select cut-off date");
        setShowAlert(true);
        return;

      }
      //setShow(false);

     
      axios({          
        method:'Post',
        url: url,             
        data:myData         
      }) 
      .then(function(response){
        if(response.data=="SUCCESS"){
          setErrorMessage("Successfully Saved");
          setShowAlert(true);       
          props.onSave(true);  
        }
      })
      .catch(function(err){
        alert(err);
        setErrorMessage("Error Encountered");
        setShowAlert(true);         
      })
    }
    
    
  
  return (
    <>
    {!hideAddButton?(
      <div style={{marginLeft:"14px",marginBottom:"10px"}}>
    <Button variant="primary" size="sm" onClick={handleShow}>
       Add New
      </Button>
      </div>
    ):''}

      <Modal     size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>            
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Status</Form.Label>
                        <Col sm="8">
                        <Form.Select      ref={cboStatus}>   
                        {StatusList.map((rs) => <option value={rs.value}>{rs.label}</option>)}
                         
                        </Form.Select>
                        </Col>
                    </Form.Group> 
                    <Form.Group as={Row}>
                            <Form.Label column sm="4">Institution</Form.Label>
                            <Col sm="8">
                               <DropdownInstitution setDefValue={institution} setValue={(e)=>selectedInstitution(e)}></DropdownInstitution>
                            </Col>
                    </Form.Group>    
                    <Form.Group as={Row}>
                            <Form.Label column sm="4">Test / Training</Form.Label>
                            <Col sm="8">
                               <DropdownTestTrainingList setDefValue={testTraining} setValue={(e)=>selectedTestTraining(e)}></DropdownTestTrainingList>
                            </Col>
                    </Form.Group>     
                    
                    <Form.Group as={Row}  controlId="txtRemarks">
                            <Form.Label column sm="4">Remarks</Form.Label>
                            <Col sm="8">
                                <Form.Control   as="textarea"  ref={txtRemarks}    placeholder="Remarks"></Form.Control>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row}  controlId="dteFrom">
                            <Form.Label column sm="4">Date</Form.Label>
                            <Col sm="8">
                                <Form.Control  type='date' ref={dteFrom}   ></Form.Control>
                            </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row}  controlId="dtCutOffDate">
                            <Form.Label column sm="4">CutOff Date</Form.Label>
                            <Col sm="8">
                                <Form.Control  type='date' ref={dtCutOffDate} ></Form.Control>
                            </Col>
                    </Form.Group>

                    
                    <Form.Group as={Row}  controlId="txtCapacity">
                            <Form.Label column sm="4">Capacity</Form.Label>
                            <Col sm="8">
                                <Form.Control  type='number'  ref={txtCapacity}  ></Form.Control>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Venue</Form.Label>
                        <Col sm="8">
                           <TrainingCenterOptions  setDefValue={trainCenter}  setValue={selectedTrainingCenter}    />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Col sm="12">
                        <Alert variant='danger' show={showAlert} style={{marginTop:"10px"}}>{errorMessage}</Alert>
                      </Col>
                    </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveForm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
