import React, { useRef } from 'react'
import { Container,Col,Row,Form, Button } from 'react-bootstrap'

const CONST_URL_PAYMENT =process.env.REACT_APP_BACKEND_PRINTING_URL + "/View/viewer.aspx";

const RegStyle={
    CardStyle:{
        marginTop: '5px'
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }
  
 }

export default function ReportsApprovalStatus() {
  const refDateFrom=useRef('');
  const refReportType=useRef('');
  const refDateTo=useRef('');
  const refStatus=useRef('All');
  const onPrintClick=()=>{
    let url=CONST_URL_PAYMENT + "?Type=ApprovalStatus&&DateFrom="+ refDateFrom.current.value + "&&DateTo="+ refDateTo.current.value + "&&Status="+ refStatus.current.value + "&&ExportType="+ refReportType.current.value;
    window.open(url, '_blank', 'noopener,noreferrer');
}
  return (
    <>
     
    <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle}>
                    <h4>Approval Status Report</h4>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Status</Form.Label>
                        <Col sm="4">
                        <Form.Select ref={refStatus}>
                            <option value={"All"}>All</option>
                            <option value={"APV"}>Approved</option>
                            <option value={"REJ"}>Rejected</option>
                            <option value={"REQ"}>Requested</option>
                        </Form.Select>
                        </Col>
                     </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Date From</Form.Label>
                        <Col sm="4">
                           <Form.Control type="date" ref={refDateFrom} ></Form.Control>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Date To</Form.Label>
                        <Col sm="4">
                           <Form.Control type="date" ref={refDateTo} ></Form.Control>
                        </Col>
                     </Form.Group>

                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Type</Form.Label>
                        <Col sm="4">
                        <select className="form-select" aria-label="Select" ref={refReportType}>  
                       
                       <option key="PDF" value="PDF">PDF</option>  
                       <option key="Excel" value="Excel">Excel</option>  
                      
                   
                   </select>
                        </Col>
                     </Form.Group>

                     <Form.Group as={Row} className="mb-3">
                     <Col sm="6" style={{textAlign:"center"}}>
                            <Button variant="primary" onClick={onPrintClick}>Print</Button>
                     </Col>
                     </Form.Group>
                </Form>
            </Col>
        </Row>
    </Container>
                    


    </>
  )
}
