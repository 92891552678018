import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Column } from "primereact/column";
import { DataTable } from  "primereact/datatable"
import { LoginContexts } from '../Contexts/LoginContexts';
import { PrimeReactProvider } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { useNavigate } from 'react-router-dom';
export default function CustomerTransGrid() {
  const URL="Api/PaymentApi/GetPaymentPerUser";
  const {userID}=useContext(LoginContexts);
  const [gridData,setGridData]=useState([]);
  const navigate=useNavigate();
  useEffect(()=>{
     
    var data={
        UserID:userID
    }
    axios({
        method:"post",
        data:data,
        url:URL
    })
    .then(function(res){
        setGridData(res.data);
    })
    .catch(function(ex){

    })
    .finally(function(ex){

    })
  },[])
  
 const statusColumn=(e)=>{
    return <Badge value={e.Status} size="medium" severity={e.Status="Pending"?"danger":"success"}></Badge>;
 }
 function OpenNewTab(fileName){
    var url=process.env.REACT_APP_BACKEND_SERVER_NAME + '/UploadedFiles/' + fileName;
    window.open(url, "_blank")
 }
 const attachmentColumn=(e)=>{
     
    return <a onClick={() => OpenNewTab(e.AttachmentFileName)} href='#'>Attachment</a>
 }
 function PrintNewTab(param){
   localStorage.setItem("refno",param);  
    var url=process.env.REACT_APP_BACKEND_PRINTING_URL + '/View/viewer.aspx?Ref='+ param + "&&Type=PaymentReceipt" ;
    window.open(url, "_blank")
   
   //navigate('/Customer/TestPermit/'+ param);  
 }
 const printColumn=(e)=>{
    return <a onClick={() => PrintNewTab(e.EncryptedReferenceNo)} href='#'>Print</a>
 }
  return (
     <>
        
       <PrimeReactProvider>
            <DataTable value={gridData} 
            paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            emptyMessage="No data found"
            className="datatable-responsive"
            currentPageReportTemplate="Showing {first} of {last} of {totalRecords}"
            rows={5}
            showGridlines
            stripedRows
             >                
                <Column body={statusColumn} field="Status" sortable header="Status"></Column>                           
                <Column field="ReferenceNo" sortable header="Transaction No."></Column>                           
                <Column field="ScheduleDate" sortable header="Schedule"></Column> 
                <Column field="TestTraningName" sortable header="Test / Training"></Column> 
                
                <Column field="DatePaid" sortable header="Date Paid"></Column>                           
                <Column field="Venue" sortable header="Venue"></Column>                           
                <Column field="BankName" sortable header="Bank"></Column>                           
                <Column field="PaymentReferenceNo" sortable header="Payment Ref. No."></Column>   
                <Column field="Amt" sortable header="Amt"></Column>
                <Column field="RejectedReason" sortable header="REJ. Reason"></Column>
                
                <Column field="EncryptedReferenceNo" sortable header="Enc" hidden="true"></Column>                                                 
                <Column body={attachmentColumn} field="AttachmentFileName"   header="View"></Column>                                 
                <Column body={printColumn}   header="Print"></Column>    
            </DataTable>
            </PrimeReactProvider>
        </>
  )
}
