import React from 'react'
import { Container } from 'react-bootstrap'
import { useContext } from 'react'
import { LoginContexts } from '../../Contexts/LoginContexts'
import { Divider } from 'primereact/divider'
import BankList from '../../Component/BankList';

import axios from 'axios'
import CustomerInfo from '../../Component/CustomerInfo'
import personalInfo from "../../Asset/Images/64/user.png";
import submitPaymentImage from "../../Asset/Images/64/bill.png";
import { useNavigate } from 'react-router-dom'
import ScheduleGrid from '../../Component/ScheduleGrid'
import Grid from '../../Component/Grid'

export default function CustomerDashboard() {
  const CONST_URL_SCHEDULE ="Api/ScheduleApi/GetScheduleListPaginated";
  const {username}=useContext(LoginContexts);
  const {userID}=useContext(LoginContexts);
  const navigate=useNavigate();

  const statusFilterlist=[
    {id:"All",value:"All"},
    {id:"Active",value:"Active"},
    {id:"Inactive",value:"Inactive"},
    {id:"Closed",value:"Closed"},
    
  ]

  const ColumnListSchedule=[
    {FieldID:'Status', Label: 'Status'},
    {FieldID:'Institution',Label:'Institution'},
    {FieldID:'TestTrainingName',Label:'Test / Training'},
    {FieldID:'DateFrom', Label: 'Date'},        
    {FieldID:'CenterName', Label: 'Venue'},
    {FieldID:'Institution', Label: 'Institution'},
    {FieldID:'MaxNo', Label: 'Max'},
    {FieldID:'Remarks' ,Label: 'Remarks'}
 
    
  ];    
  return (
    
    
    <>
    <Container>
   
    
        <div className='card'>           
          <div className='cardContent'>
          <div style={{marginTop:"20px",marginLeft:"10px"}}>
          <h4 style={{color:"#28a745"}}><img src={personalInfo} style={{height:"48px"}} /> Personal Information</h4>
          <Divider/>
          
        <CustomerInfo />
          </div>
          
            <BankList  />    
            <div style={{marginTop:"20px",marginLeft:"20px"}}>
            <h4 style={{color:"#28a745"}}><img src={submitPaymentImage} style={{height:"48px"}} />  Schedule</h4>
            <Divider/>
           
            <Grid 
      DataSource={CONST_URL_SCHEDULE} 
      ColumnList={ColumnListSchedule} 
      uniqueID="SchedID"            
      DefaultStatus='Active'
      filter={statusFilterlist}   
      setFilterPerUser={userID}>

      </Grid>
        
             
            </div>

          </div>
        </div>
    </Container>
    </>
  )
}
