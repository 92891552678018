import axios from 'axios';
import React, { useRef, useState } from 'react'
import {Form,Col,Row,Button, Alert} from "react-bootstrap"
import { useContext } from 'react';
import { LoginContexts } from '../Contexts/LoginContexts';
import "../CustomCSS/custom.css";

export default function CustomerInfoEdit() {
  const CONST_URL="Api/UserApi/GetMemberInfo";
  const CONST_URL_UPDATE="Api/UserApi/UpdateMemberAccount";
  const {username,userID} =useContext(LoginContexts);

  const[permit,setPermit]=useState('');
  const[name,setName]=useState('');
  const[age,setAge]=useState('');
  const[birthDay,setBirthDay]=useState('');
  const[contactNo,setContactNo]=useState('');
  const[email,setEmail]=useState('');
  const[password,setPassword]=useState('');
  const[gender,setGender]=useState('');
  const[nationality,setNationality]=useState('');
  const[address,setAddress]=useState('');
  const[showAlert,setShowAlert]=useState(false);
  const[errorMessage,setErrorMessage]=useState('');
  const refName=useRef();
  const refAge=useRef();
  const refBDay=useRef();
  const refContactNo=useRef();
  const refNationality=useRef();
  const refGender=useRef();
  const refEmail=useRef();
  
  const refUsername=useRef();
  const refPassword=useRef();
  const refConfirm=useRef();
  const refPermit=useRef();
  var data={
    UserName:username
  }
  axios({
    method: "post",
    url: CONST_URL ,
    data:data
  })
  .then(function(res){
    
    setPermit(res.data[0].PermitNo);
    setName(res.data[0].Name);    
    setAge(res.data[0].Age);
    setAddress(res.data[0].Address);
    setBirthDay(res.data[0].BirthDate);
    setContactNo(res.data[0].ContactNo);
    setEmail(res.data[0].EmailAddress);    
    setGender(res.data[0].Gender);
    setNationality(res.data[0].Nationality);  
    setPassword(res.data[0].UserPassword);  
          
  })
  .catch(function (error) {                
    console.log("Error" +error);
  })
  .finally(function () {
    // always executed
  });
  const SubmitForm=()=>{
    var data={
        Name:refName.current.value,
        Age: parseInt(refAge.current.value),
        BirthDate: refBDay.current.value,
        ContactNo: refContactNo.current.value,
        Nationality: refNationality.current.value,
        Gender: refGender.current.value,
        UserID:userID,
        UserName:refUsername.current.value,
        UserPassword:refPassword.current.value,
        ReConfirm:refConfirm.current.value,
        EmailAddress: refEmail.current.value,
        PermitNo:refPermit.current.value
    }


    
    setShowAlert(false);
    setErrorMessage('');
    if(data.Name==''){
        setShowAlert(true);
        setErrorMessage('Please input your name');
    }else if(data.Age==0 || isNaN(data.Age)){
        setShowAlert(true);
        setErrorMessage('Please input age');
    }else if(data.BirthDate==''){
        setShowAlert(true);
        setErrorMessage('Please select your birth day');
    }else if(data.ContactNo==''){
        setShowAlert(true);
        setErrorMessage('Please input your contact no.');
    }else if(data.Nationality==''){
        setShowAlert(true);
        setErrorMessage('Please input your nationality');
    }else if(data.EmailAddress==''){
        setShowAlert(true);
        setErrorMessage('Please input your email address');
    }else if(data.Gender==''){
        setShowAlert(true);
        setErrorMessage('Please input gender');
    }else if(data.UserName==''){
        setShowAlert(true);
        setErrorMessage('Please input username');
    }else if(data.UserPassword==''){
        setShowAlert(true);
        setErrorMessage('Please input password');
    }else if(data.ReConfirm==''){
        setShowAlert(true);
        setErrorMessage('Please input confirmation');
    }else if(data.ReConfirm!=data.UserPassword){
        setShowAlert(true);
        setErrorMessage('Password confirmation not match');
    }else{
        SaveAccount(data);
    }
  }
  function SaveAccount(e){
    axios({
        url:CONST_URL_UPDATE,
        data:e,
        method:"post"
    })
    .then(function(e){
        if(e.data=="SUCCESS"){
            setShowAlert(true);
            setErrorMessage('Account Updated');
        }else{
            setShowAlert(true);
            setErrorMessage("Can't modify your account. Please again later.");
        }
    })
  }

  return (
    
    <>
    <div style={{margin:"20px"}}>
    <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight'  sm="2">Permit No</Col>
          <Col   sm="3"><Form.Control type="number" defaultValue="" disabled ref={refPermit}  value={permit}  autoComplete='false' /></Col>
        
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight'  sm="2">Name</Col>
          <Col   sm="3">
          <Form.Control type="text"  defaultValue={name}    ref={refName} autoComplete='false' />
          </Col>
          <Col className='labelFontWeight' sm="1">Age</Col>
          <Col sm="2"> <Form.Control type="number"  ref={refAge}   defaultValue={age}  autoComplete='false' /></Col>          
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Birth Date</Col>
          <Col sm="3"><Form.Control type="date"   defaultValue={birthDay} ref={refBDay}  autoComplete='false' /></Col>   
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Contact No</Col>
          <Col sm="3"><Form.Control type="text" ref={refContactNo}   defaultValue={contactNo}  autoComplete='false' /></Col>   
          <Col className='labelFontWeight' sm="1">Email</Col>
          <Col sm="2"> <Form.Control type="text"  ref={refEmail}  defaultValue={email}  autoComplete='false' /></Col> 
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Nationality</Col>
          <Col sm="3"><Form.Control type="text"  ref={refNationality}  defaultValue={nationality}  autoComplete='false' /></Col>   
          <Col className='labelFontWeight' sm="1">Gender</Col>
          <Col sm="2"> <Form.Control type="text" ref={refGender} defaultValue={gender}  autoComplete='false' /></Col> 
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Username</Col>
          <Col sm="3"><Form.Control type="text"  ref={refUsername}   defaultValue={username}  autoComplete='false' /></Col>            
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Password</Col>
          <Col sm="3"><Form.Control type="password"  ref={refPassword}   defaultValue=""  autoComplete='false' /></Col>            
      </Form.Group>
      <Form.Group as={Row} className="mb-12" style={{marginBottom:"10px"}}>
          <Col className='labelFontWeight' sm="2">Re-confirm</Col>
          <Col sm="3"><Form.Control type="password" ref={refConfirm}   defaultValue=""  autoComplete='false' /></Col>            
      </Form.Group>
      <Form.Group as={Row} className="mb-12"style={{marginBottom:"10px"}}>
        <Col className='labelFontWeight' sm="2"></Col>
            <Col className='labelFontWeight' sm="6">
            <Alert show={showAlert} style={{textAlign:"center"}} variant="danger">{errorMessage}</Alert>
            </Col>        
        <Col className='labelFontWeight' sm="4"></Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-12"style={{marginBottom:"10px"}}>
        <Col className='labelFontWeight' sm="2"></Col>
        <Col sm="3" style={{textAlign:'right'}}>
        <Button type='button' variant='primary' onClick={SubmitForm}>Submit</Button>
        </Col>
    </Form.Group>
          
    </div>          
    </>
  )
}
