import React from 'react'
import {Container,Row,Col} from "react-bootstrap";
import CustomerTransGrid from '../../Component/CustomerTransGrid';
export default function CustomerTransaction() {
  return (
    <>
    
        <div className='card'>           
            <div className='cardContent'>
            <CustomerTransGrid />
            </div>
        </div>
    
    </>
  )
}
