import axios from 'axios';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef, useState } from 'react';
import {Container,Row,Col,Form, Alert, Button} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function BankForm() {
    const CONST_URL_SAVE="Api/BankApi/SaveData";
    const CONST_URL_GET="Api/BankApi/GetData";
    const cboStatus=useRef();
    const txtBank=useRef();
    const txtAccountNo=useRef();
    const txtAccountName=useRef();
    const {ID}=useParams();
    const navigate=useNavigate();
    const [stateError,setStateError]=useState(
        {
            showError: false,
            errorMessage:'',
            alertType:"danger",
            disableButton:false
        }
    )
    useEffect(()=>{
        if(ID>0){
            let data={
                ID:ID
            }
            axios({
                method:"post",
                url:CONST_URL_GET,
                data:data
            })
            .then(function(e){
                txtAccountNo.current.value=e.data[0].AccountNo;
                txtAccountName.current.value=e.data[0].AccountName;
                txtBank.current.value=e.data[0].BankName;
                cboStatus.current.value=e.data[0].Status;
            })
            .catch(function(ex){


            })
        }
    },[])
    const FormSubmit=(e)=>{
        e.preventDefault();
     
        setStateError({showError:false,errorMessage:"",alertType:"success"});
        if(txtBank.current.value===""){
            setStateError({showError:true,errorMessage:"Please input bank name",alertType:"danger"}); 
        }else if(txtAccountNo.current.value===""){
            setStateError({showError:true,errorMessage:"Please input account no.",alertType:"danger"}); 
        }else if(txtAccountName.current.value===""){
            setStateError({showError:true,errorMessage:"Please input account name",alertType:"danger"}); 
        }else{
            SaveForm();
        }
    
    }
    const SaveForm=()=>{
        let data={
            AccountNo: txtAccountNo.current.value,
            AccountName: txtAccountName.current.value,
            BankName:txtBank.current.value,
            Status:cboStatus.current.value,
            ID:ID
        }
        axios({
           method:"post",
           data:data,
           url:CONST_URL_SAVE
        })
        .then(function(ex){
            if(ex.data=="SUCCESS"){
                setStateError({showError:true,errorMessage:"Record Saved",alertType:"success"});
            }else{
                setStateError({showError:true,errorMessage:"Unable to save changes",alertType:"danger"});
            }
        })
        .catch(function(ex){
            setStateError({showError:true,errorMessage:"Unable to save changes : "+ ex,alertType:"danger"});
        })
    }
    const RegStyle={
        CardStyle:{
            marginTop: '5px'
        },RegFormStyle:{
            backgroundColor: 'white',
            padding: '30px'
        }
      
      
     }
     const backToList=()=>{
        navigate("/Admin/ViewBankList");

     }
  return (
    <>
    <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                    <h4>Add / Update Bank</h4>
                    <Divider/>
                    <Form.Group as={Row} className="mb-3" controlId="cboStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                            <Col sm="4">
                                <select class="form-select"  name="cboStatus" ref={cboStatus} >                                
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>                              
                                </select>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtBank">
                        <Form.Label column sm="2">Bank</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtBank" ref={txtBank} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtAccountNo">
                        <Form.Label column sm="2">Account No</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtAccountNo" ref={txtAccountNo} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtAccountName">
                        <Form.Label column sm="2">Account Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtAccountName" ref={txtAccountName} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                                <Alert show={stateError.showError} variant={stateError.alertType}>{stateError.errorMessage}</Alert>
                                
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                            <Button  style={{marginRight:"10px"}} type='button' onClick={backToList} variant='primary'>Back to Listing</Button>
                                <Button disabled={stateError.disableButton} type='submit' variant='primary'>Save</Button>
                            </Col>
                        </Form.Group>
                </Form>
                 
            </Col>
        </Row>
        </Container>
        
    </>
  )
}
