import {  useState,useEffect ,useContext} from "react";
import axios from 'axios';
import { Button } from 'primereact/button';
import Messagebox from "./Messagebox";
import Grid from "../Component/Grid";
import { LoginContexts } from "../Contexts/LoginContexts";
function ScheduleGrid(props) {       
    const CONST_URL_SCHEDULE ="Api/ScheduleApi/GetScheduleListPaginated";
    const [scheduleID,setScheduleID]=useState(0);
    const [editScheduleForm,setEditScheduleForm]=useState(0);
    const [approvalGrid,setApprovalGrid]=useState(0);
    const [refreshGrid,setRefreshGrid]=useState(0);
    const [filterByUserID,setFilterByUserID]=useState(0);
    const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(0);
    const [currentID,setCurrentID]=useState(0);    
    const CONST_URL_DELETE_SCHEDULE= "Api/ScheduleApi/DeleteSchedule";
    

    
    const statusFilterlist=[
      {id:"All",value:"All"},
      {id:"Active",value:"Active"},
      {id:"Inactive",value:"Inactive"},
      {id:"Closed",value:"Closed"},
      
    ]
    const ColumnListSchedule=[
        {FieldID:'Status', Label: 'Status'},
        {FieldID:'Institution',Label:'Institution'},
        {FieldID:'TestTrainingName',Label:'Test / Training'},
        {FieldID:'DateFrom', Label: 'Date'},        
        {FieldID:'CenterName', Label: 'Venue'},
        {FieldID:'Institution', Label: 'Institution'},
        {FieldID:'MaxNo', Label: 'Max'},
        {FieldID:'Remarks' ,Label: 'Remarks'},
        {FieldID:'RequestedCount' ,Label: 'Requested'},
        {FieldID:'ApprovedCount' ,Label: 'Approved'},        
        {FieldID:'Edit', Label: 'Edit'},
        {FieldID:'Delete', Label: 'Delete'}
        
      ];    
    useEffect(()=>{
      
      if(props.filterByUserID){
        setFilterByUserID(props.filterByUserID);
  
      }
      
      setApprovalGrid((prev)=>prev+1);
    },[props.refreshGrid,props.filterByUserID,refreshGrid])
    
    function ShowForm(data){
        
        props.onEdit(data.SchedID);
    }
    const Filter=(e)=>{

    }
    const EditSchedule=(e)=>{      
    
        setScheduleID(e);
        props.onEdit(e);
        //setEditScheduleForm((prev)=>prev+1);
      }  
      const ConfirmActionResultApprove=(e)=>{
        if(e==="Y"){
          let data={
            SchedID:currentID
          }
          axios({
            method:"post",
            url:CONST_URL_DELETE_SCHEDULE,
            data:data
          })
          .then(function(ex){
            setRefreshGrid((prev)=>prev+1);
            if(ex.data[0].ErrorMessage!="SUCCESS"){
                alert("Error Encountered");
  
            }
  
          })
          .catch(function(ex){
            alert("Error Encountered");
          })
        }
      }
     const EditButton=(e)=>{
        
        return <Button label="Edit" as="a" size="xs" value={e.SchedID} onClick={()=>ShowForm(e)}></Button>;
     }
     
     const onDelete=(e)=>{
      setCurrentID(e);
      setShowMessageConfirmApprove((prev)=>prev+1);
     }
     return (
        <>

<Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to delete?"} /> 
        
        
        <Grid 
      DataSource={CONST_URL_SCHEDULE} 
      ColumnList={ColumnListSchedule} 
      uniqueID="SchedID"      
      onEdit={EditSchedule} 
      onDelete={onDelete}
      refreshGrid={approvalGrid}
      filter={statusFilterlist}   
      DefaultStatus=''
      setFilterPerUser={filterByUserID}
      
    />
        
        </>
    )  
   
}

export default ScheduleGrid