import React, { useState } from 'react'
import { Route,Routes } from 'react-router-dom'
import Registration from './Pages/Registration'
import Member from './Pages/Admin/Member'
import PublicLayout from './Pages/SiteLayout/PublicLayout';
import AdminLayout from './Pages/SiteLayout/AdminLayout'
import Home from './Pages/Home';
import Schedule from './Pages/Schedule';
import ScheduleAdmin from './Pages/Admin/Schedule';
import UploadMember from './Pages/Admin/UploadMember'
import CustomerLayout from './Pages/SiteLayout/CustomerLayout'
import CustomerDashboard from './Pages/Customer/CustomerDashboard'
import { LoginContexts } from './Contexts/LoginContexts'
import ProtectedRoute from './Utils/ProtectedRoute';
import PaymentSuccess from './Pages/Customer/PaymentSuccess';
import CustomerTransaction from './Pages/Customer/CustomerTransaction';
import CustomerEditAccount from './Pages/Customer/CustomerEditAccount';
import TestReducer from './Test/TestReducer';
import AdminLogin from './Pages/Admin/AdminLogin';
import AdminDashboard from './Pages/Admin/AdminDashboard';
import AdminLoginLayout from './Pages/SiteLayout/AdminLoginLayout';
import MemberRegistration from './Pages/Admin/MemberRegistration';
import AdminAccount from './Pages/Admin/AdminAccount';
import AdminAccountForm from './Pages/Admin/AdminAccountForm';
import ViewPermitList from './Pages/Admin/ViewPermitList';
import ViewTestTrainList from './Pages/Admin/ViewTestTrainList';
import Reports from './Pages/Admin/Reports';
import ReportsApprovalStatus from './Pages/Admin/ReportsApprovalStatus';
 
import ContactUs from './Pages/ContactUs';
import ActivateAccount from './Pages/ActivateAccount';
import ViewBankList from './Pages/Admin/ViewBankList';
import BankForm from './Pages/Admin/BankForm';
import TestForm from './Test/TestForm';
import  "./Interceptor/axios";  
import Institution from './Pages/Admin/Institution';
import InstitutionForm from './Pages/Admin/InstitutionForm';
import ForgetPassword from './Pages/ForgetPassword';
import TestPrint from './Test/TestPrint';
import TestPermit from './Pages/Customer/TestPermit';
import TestSummary from './Test/TestSummary';
import ReportMasterlist from './Pages/Admin/ReportMasterlist';
import { TestScheduleGrid } from './Test/TestScheduleGrid';
import SubmitPaymentStep1 from './Pages/Customer/SubmitPaymentStep1';
import SubmitPayment from './Pages/Customer/SubmitPayment';
import TestTrainingForm from './Pages/Admin/TestTrainingForm';
export default function MyRoute() {
  const [username,setUsername]=useState('');
  const [userID,setUserID]=useState('');
  const [accountType,setAccountType]=useState('');
    if(username==''){
        setUsername(localStorage.getItem('username'));
    }else if(userID==0){
      setUserID(localStorage.getItem('userid'));
    }else if(accountType==''){
      setAccountType(localStorage.getItem('accountType'));
    }
  return (
    <>
    <LoginContexts.Provider value={{username,setUsername,userID,setUserID,accountType,setAccountType}}>
    <Routes>
      <Route path='*' element={"Page Not Found"}></Route>
      <Route path='/Test' element={<SubmitPaymentStep1/>}></Route>       
      <Route path='/' element={<PublicLayout/>}>
          <Route path='/' element={<Home/>}></Route>      
          <Route path='/Registration' element={<Registration/>}></Route>    
          <Route path='/Schedule' element={<Schedule />}></Route>  
          <Route path='/ForgetPassword' element={<ForgetPassword />}></Route>  
          
          <Route path='/ContactUs' element={<ContactUs />}></Route>  
          <Route path='/ActivateAccount/:UserID' element={<ActivateAccount />}></Route>  
          
      </Route>  
      <Route path='/A' element={<AdminLoginLayout/>}>
        <Route path='Login' element={<AdminLogin/>}></Route>
      </Route>
      <Route path='/Admin' element={<AdminLayout/>}>        
        <Route element={<ProtectedRoute/>}>
          <Route path='MemberRegistration' element={<MemberRegistration/>}></Route>      
          <Route path='EditMemberRegistration/:ID' element={<MemberRegistration/>}></Route>                  
          <Route path='BankForm/:ID' element={<BankForm />}></Route>
          <Route path='InstitutionForm/:ID' element={<InstitutionForm />}></Route>
          <Route path='TestTrainingForm/:ID' element={<TestTrainingForm/>}></Route>
          <Route path='Member' element={<Member/>}></Route>
          <Route path="Reports" element={<Reports/>} ></Route>
          <Route path="ReportsApprovalStatus" element={<ReportsApprovalStatus/>} ></Route>
          <Route path="ReportMasterlist" element={<ReportMasterlist/>}></Route>
          
          <Route path='Schedule' element={<ScheduleAdmin/>}></Route>
          <Route path='UploadMember' element={<UploadMember />}></Route>
          <Route path='ViewPermitList' element={<ViewPermitList />}></Route>          
          <Route path='ViewTestTrainList' element={<ViewTestTrainList />}></Route> 
          <Route path='ViewBankList' element={<ViewBankList />}></Route> 
          <Route path='AdminDashboard' element={<AdminDashboard/>}></Route>        
          <Route path='AdminAccount' element={<AdminAccount/>}></Route>       
          <Route path='AdminAccountForm' element={<AdminAccountForm/>}></Route>
          <Route path='Institution' element={<Institution/>}></Route>
          <Route path=':AdminID' element={<AdminAccountForm/>}></Route>
        </Route>    
      </Route>
      <Route path='/Customer' element={<CustomerLayout/>}>       
      <Route element={<ProtectedRoute/>}>
        <Route path='CustomerDashboard' element={<CustomerDashboard/>}></Route> 
        <Route path='PaymentSuccess/:RefID' element={<PaymentSuccess/>}></Route> 
        <Route path='TestPermit/:RefIDQuery' element={<TestPermit/>}></Route> 
        <Route path='SubmitPayment' element={<SubmitPayment/>}></Route> 
        <Route path='CustomerTransaction' element={<CustomerTransaction />}></Route>             
        <Route path='CustomerEditAccount' element={<CustomerEditAccount />}></Route>  
      </Route>
      </Route>    
    </Routes>
    </LoginContexts.Provider>
    </>
  )
}
