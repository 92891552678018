import React, { useRef } from 'react'
import { Container,Row,Col,Form,Alert,Button, Card } from 'react-bootstrap'
import PublicCard from '../Component/PublicCard'
import { useState,useContext } from 'react';
import social from '../Images/social.png';
import {  Link,useNavigate  } from 'react-router-dom';

import axios from 'axios';
import { LoginContexts } from '../Contexts/LoginContexts';
import thumbnail from '../Images/thumbnail.png';
import mainImage from '../Images/main.png';
import Registration from './Registration'; 
export default function Home() {
  const CONST_VALIDATE_LOGIN_URL='Api/UserApi/Memberlogin'
  const [showAlert,setShowAlert]=useState(false);
  const[errMessage,setErrMessage]=useState('');
  const[alertType,setAlertType]=useState('danger');
  const [buttonState,setButtonState]=useState(false);
  const [buttonText,setButtonText]=useState('Login');
  const refUsername =useRef('');
  const refPassword=useRef('');  
  const {setUsername,setUserID,setAccountType}=useContext(LoginContexts);
  
  const navigate=useNavigate();

  const RedirectToRegistration=()=>{
    navigate('./Registration')
  }
  
  function submitLoginCredentials(){
    var data={
        UserName:refUsername.current.value,
        UserPassword:refPassword.current.value
    }               
 //  alert(axios.defaults.baseURL);
    axios(
        {
        method: "post",
        url:CONST_VALIDATE_LOGIN_URL,        
        timeout: 20000, // Set a timeout of 20 seconds
        data:data
        }
    )
  
    .then(function (response) {        
        if(response.data[0].ErrorMessage=='INVALID'){
            setShowAlert(true);
            setErrMessage('Invalid Username / Password');
        }else   if(response.data[0].ErrorMessage=='INACTIVE'){
            setShowAlert(true);
            setErrMessage('Your account is deactivated. Please activate it in the email sent (Inbox/Spam) folder.');
        }else{
            setUsername(refUsername.current.value);
            setUserID(response.data[0].UserID);
            setAccountType("Customer");
            
            localStorage.setItem("username",refUsername.current.value);
            localStorage.setItem("userid",response.data[0].UserID);
            navigate('/Customer/CustomerDashboard')                   
        }
    })
    .catch(function(ex){
        alert(ex);
        setButtonState(false);
        setButtonText("Login");
    })
    .finally(function(){
        setButtonState(false);
        setButtonText("Login");
    })

  }
  function FormSubmit(e){
    e.preventDefault();
    setShowAlert(false);
    setErrMessage('');
    
    if(refUsername.current.value==''){
        setShowAlert(true);
        setErrMessage('Please input username');
    }else if(refPassword.current.value==''){
        setShowAlert(true);
        setErrMessage('Please input password');
    }else{
        submitLoginCredentials();
    }
  }
  const RegStyle={
   RegFormStyle:{       
        marginTop:"10px"

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    },
    jumbotron:{
        backgroundImage: `url($card)` 
    }

 }


  
  return (
    <>
    <div className='container' >
     

    

     
    







  
        <div className='row'>
            <div className='col-lg-8'>
               <PublicCard></PublicCard>
            </div>
            <div className='col-lg-4'>
                <form style={{ backgroundColor:"white"}}  onSubmit={FormSubmit}>
                    <div style={{margin:"20px"}}>
                        <br></br>
                        <h4>Login</h4>
                      
                        <div className="form-outline mb-4">
                            <input type="text" id="txtUsername" className="form-control" defaultValue="" ref={refUsername}  autoComplete='false' />
                            <label className="form-label"   htmlFor="txtUsername">Username</label>
                        </div>
                        <div className="form-outline mb-4">
                            <input  id="txtPassword" type="password" className="form-control"  ref={refPassword}  defaultValue="" />
                            <label className="form-label" htmlFor="txtPassword">Password</label>
                        </div>  
                        <div className="row mb-4">
                            <div className="col">
                            <Alert show={showAlert} variant={alertType}>{errMessage}</Alert>
                            </div>
                        </div>
                      
                     
                        <button type="submit"  disabled={buttonState} style={{width:"100%"}}  variant='primary' className="btn btn-primary btn-block mb-4">{buttonText}</button>
                        <button type="button" onClick={RedirectToRegistration}   style={{width:"100%"}}  variant='primary' className="btn btn-primary btn-block mb-4">Register</button>
                        <div className="text-center" style={{paddingBottom:"20px",display:"none"}}>
                            <Link to="/Registration" >    Not yet a member ? Create Account</Link>
                        </div>
                        <div className="row mb-4" >
                            <div className="col" style={{marginBottom:"20px"}}>
                                
                                <Link to="/ForgetPassword" >Forgot password?</Link>
                            </div>
                        </div>
                      
                    </div>
                </form>
            </div>
        </div>
</div>

     
    </>
  )
}
