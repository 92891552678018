import React, { useState } from 'react'
import Grid from "../../Component/Grid";
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Messagebox from '../../Component/Messagebox';
import axios from 'axios';
export default function AdminAccount() {
    const CONST_URL_GET_LIST="Api/AdminAccountApi/GetAdministratorList"
    const CONST_URL_GET_DEACTIVATE="Api/AdminAccountApi/DeactivateAccount"
    const navigate=useNavigate();
    const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(false);
    const [id,setID]=useState(0);
    const statusFilterlist=[
        {id:"All",value:"All"},
        {id:"Active",value:"Active"},
        {id:"Inactive",value:"Inactive"},
        
      ]
    const ColumnList=[
        {FieldID:'UserID', Label: 'ID'},
        {FieldID:'Status', Label: 'Status'},
        {FieldID:'Name', Label: 'Name'}    ,
        {FieldID:'UserName', Label: 'Username'},
        {FieldID:'Edit', Label: 'Edit'}       ,
        {FieldID:'Delete', Label: 'Delete'}
      ];
    const [refreshGridSource,setRefreshGridSource]=useState();

    const onEdit=(id)=>{
        navigate("/Admin/"+id);
    }
    const AddNew=()=>{
        navigate("/Admin/0")
    }
    const onDelete=(id)=>{
        setID(id);
        setShowMessageConfirmApprove((prev)=>prev+1);
    }
    const ConfirmActionResultApprove=(e)=>{
      if(e==="Y"){
        if(id>=1){
            let data={
                UserID:id
            }
            axios({
                url:CONST_URL_GET_DEACTIVATE,
                method:"post",
                data:data
            })
            .then(function(res){
                setRefreshGridSource((prev)=>prev+1);
            })
            .catch(function(ex){

            })
            

        }
      }

    }
   
   
  return (    
    <>    
     <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to deactivate?"} /> 
   
    <div style={{marginLeft:"15px",marginBottom:"10px",marginTop:"10px"}}>
        <h5>Admin List</h5>
  <Divider />

    <Button variant="primary" size="sm" onClick={AddNew}  >
    Add New
    </Button>


    </div>
     <Grid 
      DataSource={CONST_URL_GET_LIST} 
      ColumnList={ColumnList} 
      uniqueID="UserID" 
      onEdit={onEdit}       
      onDelete={onDelete}
      refreshGrid={refreshGridSource}       
      filter={statusFilterlist}
      LinkColumn="Name"
      DefaultStatus="All"
    />
    </>
  )
}
