import axios from 'axios';
import React, { useRef, useState } from 'react'
import {Form,Col,Row} from "react-bootstrap"
import { useContext } from 'react';
import { LoginContexts } from '../Contexts/LoginContexts';
import "../CustomCSS/custom.css";

export default function CustomerInfo() {
  const CONST_URL=process.env.REACT_APP_BACKEND_SERVER_NAME + "/Api/UserApi/GetMemberInfo";
  const {username} =useContext(LoginContexts);

  const[permit,setPermit]=useState('');
  const[name,setName]=useState('');
  const[age,setAge]=useState('');
  const[birthDay,setBirthDay]=useState('');
  const[contactNo,setContactNo]=useState('');
  const[email,setEmail]=useState('');
  const[gender,setGender]=useState('');
  const[nationality,setNationality]=useState('');
  const[address,setAddress]=useState('');
  var data={
    UserName:username
  }
 
  axios({
    method: "post",
    url: CONST_URL ,
    data:data
  })
  .then(function(res){
    
    setPermit(res.data[0].PermitNo);
    setName(res.data[0].Name);    
    setAge(res.data[0].Age);
    setAddress(res.data[0].Address);
    setBirthDay(res.data[0].BirthDate);
    setContactNo(res.data[0].ContactNo);
    setEmail(res.data[0].EmailAddress);    
    setGender(res.data[0].Gender);
    setNationality(res.data[0].Nationality);        
  })
  .catch(function (error) {                
    console.log("Error" +error);
  })
  .finally(function () {
    // always executed
  });
  return (
    
    <>
    <div style={{margin:"20px"}}>
    <Form.Group as={Row} className="mb-12">
          <Col className='labelFontWeight'  sm="2">Permit No</Col>
          <Col   sm="2">{permit}</Col>
        
      </Form.Group>
      <Form.Group as={Row} className="mb-12">
          <Col className='labelFontWeight'  sm="2">Name</Col>
          <Col   sm="2">{name}</Col>
          <Col className='labelFontWeight' sm="2">Age</Col>
          <Col sm="2">{age}</Col>
          <Col className='labelFontWeight' sm="2">Birth Date</Col>
          <Col sm="2">{birthDay}</Col>
      </Form.Group>
          <Form.Group as={Row} className="mb-12">
          <Col className='labelFontWeight' sm="2">Contact No</Col>
          <Col sm="2">{contactNo}</Col>
          <Col className='labelFontWeight' sm="2">Email</Col>
          <Col  sm="2">{email}</Col>
          <Col className='labelFontWeight' sm="2">Permit No</Col>
          <Col sm="2">{  permit }</Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-12">
          <Col className='labelFontWeight' sm="2">Gender</Col>
          <Col  sm="2">{gender}</Col>
          <Col className='labelFontWeight' sm="2">Nationality</Col>
          <Col sm="2">{nationality}</Col>
          
          </Form.Group>
          </div>
    </>
  )
}
