import React, { useRef, useState } from 'react'
import { Container,Row,Col,Form, Button, Alert } from 'react-bootstrap'
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PublicCard from '../Component/PublicCard';
import DropdownInstitution from '../Component/DropdownInstitution';
import validator from "validator";
import Institution from './Admin/Institution';
export default function Registration() {
    const[errMessage,setErrMessage]=useState('');
    const[alertType,setAlertType]=useState('danger');
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const formUserName=useRef('');
    const formAddress=useRef('');
    const formGender=useRef('');
    
    const formBdate=useRef('');
    const formContactNo=useRef('');
    const formEmailAdd=useRef('');
    const passMinLevel=useRef('');
    const refNationality=useRef('');
    
    const formPermitNo=useRef('');
    const [showAlert,setShowAlert]=useState(false);
    const formName=useRef('');

    const formFName=useRef('');
    const formMName=useRef('');
    const formLName=useRef('');
    
    const formPassword=useRef('')
    const formRePassword=useRef('');
    const [disableButton,setDisableButton]=useState(false);
    const CONST_URL_REGISTER= "Api/RegistrationApi/Register";
    const CONST_URL_INSTITUTION= "Api/InstitutionApi/GetData";
    const [hideIns,setHideIns]=useState(true);
    const PMMACode=1;
    const [institution,setInstitution]=useState(0);
    function FormSubmit(e){
        e.preventDefault();
      //  console.log(process.env);
      //  alert(process.env.REACT_APP_REGISTRATION_API);
      
        let formData='';
        formData={
            Name: formFName.current.value + ' ' + formMName.current.value + ' ' + formLName.current.value,
            Address: formAddress.current.value,            
            BirthDate: formBdate.current.value,
            ContactNo:formContactNo.current.value,
            EmailAddress:formEmailAdd.current.value,
            PermitNo:formPermitNo.current.value,
            UserName:formUserName.current.value,
            UserPassword:formPassword.current.value,
            RePassword:formRePassword.current.value,
            Nationality:refNationality.current.value,
            Gender:formGender.current.value,
            Type:"P",//Pre-registration
            insID:institution,
            Firstname:formFName.current.value,
            Middlename: formMName.current.value,
            LastName:formLName.current.value
        }
        
         
     
        if(formData.Firstname==''){
            setErrMessage('Please input your first name');
            setShowAlert(true);
        }else if(formData.Middlename==''){
            setErrMessage('Please input your middle name');
            setShowAlert(true);
        }else if(formData.LastName==''){
            setErrMessage('Please input your last name');
            setShowAlert(true);
        }else  if(formData.Name.includes("@")){
            setErrMessage('Invalid Name.Special character is not allowed in the field name');
            setShowAlert(true);

        }else  if(formData.Address==""){
            setErrMessage('Please input your address');
            setShowAlert(true); 
        }else  if(formData.BirthDay==""){
            setErrMessage('Please select your birthday');
            setShowAlert(true);
        }else if(formData.ContactNo==""){
            setErrMessage('Please input your contact no');
            setShowAlert(true);
        }else if(formData.Nationality==""){
            setErrMessage('Please input your nationality');
            setShowAlert(true);
        }else if(formData.EmailAddress==''){
            setErrMessage('Please input your email address');
            setShowAlert(true);
        }else if (!validator.isEmail(formData.EmailAddress)) {  
            setErrMessage('Please input valid email address');
            setShowAlert(true);     
        }else if(institution==''){
            setErrMessage('Please select institution');
            setShowAlert(true);
        }else if(formData.PermitNo=='' && (institution==PMMACode || institution=="PMMA")  ){
            setErrMessage('Please input permit no.');
            setShowAlert(true);
        }else if(formData.UserName==""){
            setErrMessage('Please input username');
            setShowAlert(true);
        }else if(formData.UserPassword==""){
            
            setErrMessage('Please input password');
            setShowAlert(true);
        }else if(passMinLevel.current.value=="Weak"){
            setErrMessage('The password you have selected is weak');
            setShowAlert(true);
        }else if(formData.RePassword==""){
            setErrMessage('Please input password confirmation');
            setShowAlert(true);
        }else if(formData.UserPassword!=formData.RePassword){
            setErrMessage('Password confirmation not match');
            setShowAlert(true);
        }else{

            SaveRegistration(formData);
        }  
     
            
    }
    function ClearFields(){
        formName.current.value='';
        formAddress.current.value='';        
        formBdate.current.value='';
        formContactNo.current.value='';
        formEmailAdd.current.value='';
        formPermitNo.current.value='';
        formUserName.current.value='';
        formPassword.current.value='';
        formRePassword.current.value=''
        
      
        

    }
    function SaveRegistration(myData){       
        setDisableButton(true);
        axios({
            method: 'post',
            url: CONST_URL_REGISTER,
            data: myData,
            timeout: 20000 // Set a timeout of 20 seconds
          })
          .catch(function(ex){
            alert(ex);
          })
          .then(function (response) {
            setDisableButton(false);
             if(response.data=="SUCCESS"){
                setAlertType('success');
                //setErrMessage('You have successfully registered to the system.Please check your email to activate your account');
                setErrMessage('You have successfully registered to the system.');
                setShowAlert(true);
                toast.success("You have successfully registered to the system!", {
                    position: "top-center"
                  });
                 // ClearFields();
                 
            }else if(response.data=="PERMIT_USED"){   
                setAlertType('success');
                setErrMessage('The permit no you have provided is already used.');
                setShowAlert(true);
            }else if(response.data=="USER_EXIST"){   
                setAlertType('success');
                setErrMessage('Username already exist. Please choose another.');
                setShowAlert(true);
             }else if(response.data=="PERMIT_EXIST" && institution==PMMACode){
                setAlertType('success');
                setErrMessage('Your permit no. is not authorize to access the system');
                setShowAlert(true);
                
            }else if(response.data=="EMAIL_EXIST"){
                setAlertType('success');
                setErrMessage('Email Address is already used.');
                setShowAlert(true);
             }else{
                alert(response.data);
                setAlertType('danger');
                setErrMessage('Please check your internet connection');
                setShowAlert(true);
                console.log(response);
             }
          })
           

    }

 const RegStyle={
    CardStyle:{
     
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }

 }
 const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";  

        setPassword(passwordValue);
     
        setMessage(strength);
    

     
    console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };
  const getActiveColor = (type) => {
    var ret="#FF0054";
    
    if (type === "Strong"){       
        return "#8BC926";
    } 
    if (type === "Medium"){
        return "#FEBD01";
    } //
    return "#FF0054";
  };
  const setInsValue=(e)=>{
    
     setInstitution(0);
     let param={
        insID:e
     }
     if(e>0){
        axios({
            method: 'post',
            url: CONST_URL_INSTITUTION,
            data: param
        })
        .then(function(res){
            setInstitution(e);
            if(res.data[0].WithPermitValidation=="Y"){
                setHideIns(false);
            }else{
                setHideIns(true);
            }
        })
        .catch(function(ex){
            alert(ex);
        })
      }else{
        setHideIns(true);
      }

     
     
  }
  return (
    <>
    <ToastContainer />
    <div className='container'>
        <div className='row' style={{marginTop:"10px"}}>
            <div className='col-lg-2'></div>
            <div className='col-lg-8'>
                <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                    <h4>Register</h4>
                    <Form.Group as={Row} style={{display:"none"}} className="mb-3" controlId="formPlaintextEmail">
                        <Form.Label column sm="4">Name</Form.Label>
                            <Col sm="8" >
                                <Form.Control type="text" defaultValue="" ref={formName} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">First Name</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"    ref={formFName} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Middle Name</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={formMName} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Last Name</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={formLName} autoComplete='off' />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Address</Form.Label>
                            <Col sm="8">
                                <Form.Control   as="textarea"  ref={formAddress}  autoComplete='off' placeholder="Address"></Form.Control>
                            </Col>
                        </Form.Group>                       
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Birthdate</Form.Label>
                            <Col sm="8">
                            <Form.Control type="date"  ref={formBdate} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Gender</Form.Label>
                            <Col sm="8">
                                <select class="form-select" aria-label="" ref={formGender}>                               
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>                                
                                </select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Contact No</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={formContactNo} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Nationality</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={refNationality} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Email Address</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"   ref={formEmailAdd} autoComplete='off' />
                            </Col>
                        </Form.Group>
                      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Institution</Form.Label>
                            <Col sm="8">
                            <DropdownInstitution setDefValue={0} setValue={setInsValue}></DropdownInstitution>
                            </Col>
                        </Form.Group>
                       
                        { hideIns==false?
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword"  >
                            <Form.Label column sm="4">Permit No.</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={formPermitNo}  autoComplete='off' />
                            </Col>
                        </Form.Group>   
                         :''
                        }                                                             
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Username</Form.Label>
                            <Col sm="8">
                            <Form.Control type="text"  ref={formUserName} autoComplete='off' />
                            </Col>
                        </Form.Group>      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Password</Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"  ref={formPassword}   onChange={({ target }) => {
                  handlePassword(target.value);
                }} />
                            </Col>
                        </Form.Group>      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="4">Re-confirm Password</Form.Label>
                            <Col sm="8">
                            <Form.Control type="password"  ref={formRePassword} />
                            </Col>
                        </Form.Group>  

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12" style={{textAlign:'center'}}>
                            {password.length !== 0 ? (
                                <p className="message" style={{ color: getActiveColor(message) }}>
                                Your password is {message}
                                </p>
                                ) : null}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12" style={{textAlign:'center'}}>
                                <Alert show={showAlert} variant={alertType}>{errMessage}</Alert>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="12" style={{textAlign:'center'}}>
                                <Button disabled={disableButton} type='submit' variant='primary'>Register</Button>
                            </Col>
                        </Form.Group>
                     

                     
                        <input type="hidden" value={message} ref={passMinLevel} ></input>
                    </Form>        
                    </div>       
                    </div>
                    </div>
    </>
  )
}
