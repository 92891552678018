import {  useState } from "react";
import axios from 'axios';
import Grid from "../Component/Grid";
import Messagebox from "./Messagebox";
import {useNavigate} from "react-router-dom";
import DropdownInstitution from "./DropdownInstitution";
function MemberGrid() {
    const CONST_URL_GET_MEMBERLIST= "Api/userApi/GetMemberList";
    const CONST_URL_DEACTIVATE_MEMBER= "Api/userApi/DeactivateMember";
    const CONST_URL_DELETE_MEMBER= "Api/userApi/DeleteMember";
    const [refreshGrid,setRefreshGrid]=useState(0);
    const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(0);
    const [currentID,setCurrentID]=useState(0);
    const [institution,setInstitution]=useState(0);
    const navigate=useNavigate();
    const statusFilterlist=[
        {id:"All",value:"All"},
        {id:"Active",value:"Active"},
        {id:"Inactive",value:"Inactive"},
        
      ]
    const ColumnList=[
        {FieldID:'UserID', Label: 'ID'},
        {FieldID:'insName', Label: 'Institution'},       
        {FieldID:'Status', Label: 'Status'},
        {FieldID:'Name', Label: 'Name'},
        {FieldID:'Address', Label: 'Address'},
        {FieldID:'Age', Label: 'Age'},
        {FieldID:'BirthDate', Label: 'BirthDate'},
        {FieldID:'ContactNo', Label: 'Contact No'},
        {FieldID:'EmailAddress', Label: 'Email'},           
        {FieldID:'Edit', Label: 'Edit'}, 
        {FieldID:'Delete', Label: 'Delete'}   
      ];
     const refreshGridSource=()=>{
        setRefreshGrid((prev)=>prev+1);
     }
     const onEdit=(e)=>{
      
      navigate("/Admin/EditMemberRegistration/"+ e);
     } 
     const onDelete=(e)=>{
      setCurrentID(e);
      setShowMessageConfirmApprove((prev)=>prev+1);
     }
    const ConfirmActionResultApprove=(e)=>{
      if(e==="Y"){
        let data={
          UserID:currentID
        }
        axios({
          method:"post",
          url:CONST_URL_DELETE_MEMBER,
          data:data
        })
        .then(function(ex){
          setRefreshGrid((prev)=>prev+1);
          if(ex.data[0].ErrorMessage!="SUCCESS"){
              alert("Error Encountered");

          }

        })
        .catch(function(ex){
          alert("Error Encountered");
        })
      }
    }

    const setInsValue=(e)=>{
      
      setInstitution(e);
    }
        
     return (
        <>


<div className='row'>    
          
        
              <div className='col-lg-3'>
                  <div className="input-group mb-3" style={{marginLeft:"10px"}}>        
                      <div className="input-group-prepend">
                          <label className="input-group-text" >Filter</label>
                      </div>
                        <DropdownInstitution setValue={setInsValue}></DropdownInstitution>
                  </div>       
              </div>
      </div>
  <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to delete?"} /> 
  
     <Grid 
      DataSource={CONST_URL_GET_MEMBERLIST} 
      ColumnList={ColumnList} 
      uniqueID="UserID" 
      onEdit={onEdit}  
      onDelete={onDelete}     
      refreshGrid={refreshGridSource}
       
      filter={statusFilterlist}
      LinkColumn="Name"
      DefaultStatus="All"
      filterByInstitution={institution}
    />
        
      
        
        </>
    )  
   
}
export default MemberGrid;
