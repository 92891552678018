import axios from 'axios';
import { Divider } from 'primereact/divider';
import React, { useEffect, useRef,useState } from 'react'
import { Container,Row,Col,Form,Button, Alert } from 'react-bootstrap'
import { useParams } from 'react-router-dom';

export default function AdminAccountForm() {
  const CONST_URL_SAVE="Api/AdminAccountApi/InsertUpdateAccount";
  const CONST_URL_GET="Api/AdminAccountApi/GetAdminInfo";
  const[disableButton,setDisableButton]=useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert,setShowAlert]=useState(false);
  const alertType=useRef();   
  const errMessage=useRef();  
  const {AdminID}=useParams();
  const txtName=useRef();
  const txtUsername=useRef();
  const cboStatus=useRef();
  const txtPassword=useRef();
  const txtConfirm=useRef();
    useEffect(()=>{
        if(AdminID>0){
            let data={
                UserID:AdminID
            }
            axios({
                method:"post",
                url:CONST_URL_GET,
                data:data
            })
            .then(function(res){
                txtName.current.value=res.data[0].Name;
                cboStatus.current.value=res.data[0].Status;
                txtUsername.current.value=res.data[0].UserName;
                txtPassword.current.value=res.data[0].UserPassword;
                txtConfirm.current.value=res.data[0].UserPassword;
                    
            })
            .catch(function(ex){

            })
        }
    },[])  

  const FormSubmit=(e)=>{
    e.preventDefault();
    const form = e.target.elements;
    
    alertType.current="danger";
    setShowAlert(false);      
    errMessage.current="";
    if(form.cboStatus.value===''){
        setShowAlert(true);      
        errMessage.current="Please select status";
    }else  if(form.txtName.value===''){
        setShowAlert(true);      
        errMessage.current="Please input name";
    }else  if(form.txtUsername.value===''){
        setShowAlert(true);      
        errMessage.current="Please input username";
    }else  if(form.txtPassword.value===''){
        setShowAlert(true);      
        errMessage.current="Please input password";
    }else if(form.txtConfirm.value===''){
        setShowAlert(true);      
        errMessage.current="Please input password confirmation";
    }else if(password==="Weak"){
        setShowAlert(true);      
        errMessage.current="Your password is weak";
    }else{
        let data={
            UserName:form.txtUsername.value,
            Name:form.txtName.value,
            Status:form.cboStatus.value,
            UserPassword:form.txtPassword.value,
            UserID:AdminID
        }
        saveInfo(data);

    }
    
  }
    const saveInfo=(data)=>{
     
        setDisableButton(true);
        axios({
            method:"post",
            url:CONST_URL_SAVE,
            data:data
        })
        .then(function(res){
            if(res.data[0].ErrorMessage==="SUCCESS"){
                setShowAlert(true);      
                errMessage.current="Successfully Saved";
            }else{
                setShowAlert(true);      
                errMessage.current=res.data[0].ErrorMessage;
            }
        })
        .catch(function(ex){
            setShowAlert(true);      
            errMessage.current="Unable to process your request";

        })
        .finally(function(e){
            setDisableButton(false);
        })

    }
  const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length === 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";  

        setPassword(passwordValue);
     
        setMessage(strength);
      

     
    console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };
  const RegStyle={
    CardStyle:{
        marginTop: '5px'
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }
  
 }
 const getActiveColor = (type) => {
    var ret="#FF0054";
    
    if (type === "Strong"){       
        return "#8BC926";
    } 
    if (type === "Medium"){
        return "#FEBD01";
    } 
    return "#FF0054";
  };
  const onChangeStatus=(e)=>{
    alert(e);
   // setStatus(e.target.value);
  }
  return (
   <>
    
   <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                    <h4>Add / Update Account</h4>
                    <Divider/>
                    <Form.Group as={Row} className="mb-3" controlId="cboStatus">
                        <Form.Label column sm="2">Status</Form.Label>
                            <Col sm="4">
                                <select class="form-select"  name="cboStatus" ref={cboStatus} >                                
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>                              
                                </select>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtName">
                        <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text"  name="txtName" ref={txtName} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtUsername">
                        <Form.Label column sm="2">Username</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" defaultValue="" name="txtUsername"  ref={txtUsername} autoComplete='off' />
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="txtPassword">
                            <Form.Label column sm="2">Password</Form.Label>
                            <Col sm="4">
                            <Form.Control type="password"   name="txtPassword"  ref={txtPassword}  onChange={({ target }) => {
                  handlePassword(target.value);
                }} />
                            </Col>
                    </Form.Group>      
                        <Form.Group as={Row} className="mb-3" controlId="txtConfirm">
                            <Form.Label column sm="2">Re-confirm Password</Form.Label>
                            <Col sm="4">
                            <Form.Control type="password" name="txtConfirm"  ref={txtConfirm}  />
                            </Col>
                        </Form.Group>  

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                            {password.length !== 0 ? (
                                <p className="message" style={{ color: getActiveColor(message) }}>
                                Your password is {message}
                                </p>
                                ) : null}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                                <Alert show={showAlert} variant={alertType.current}>{errMessage.current}</Alert>
                                
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                                <Button disabled={disableButton} type='submit' variant='primary'>Save</Button>
                            </Col>
                        </Form.Group>

                </Form>
            </Col>
        </Row>
    </Container>

   </>
  )
}
