import React, { useState } from 'react'
import Grid from '../../Component/Grid';
import { Button } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import Messagebox from '../../Component/Messagebox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function ViewBankList() {
    const CONST_URL_GET_LIST="Api/BankApi/GetGridList";
    const CONST_URL_GET_DEACTIVATE="Api/BankApi/Deactivate";
    const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(false);
    const [refreshGridSource,setefreshGridSource]=useState(0);
    const [currentID,setCurrentID]=useState(0);
    const navigate =useNavigate();
    const ColumnList=[
        {FieldID:'ID', Label: 'Id'},
        {FieldID:'Status', Label: 'Status'},
        {FieldID:'BankName', Label: 'Bank'},
        {FieldID:'AccountNo', Label: 'Account No'},
        {FieldID:'AccountName', Label: 'Name'},
        {FieldID:'Edit', Label: 'Edit'},
        {FieldID:'Delete', Label: 'Delete'}
           
    ]
    const statusFilterlist=[
        {id:"All",value:"All"},
        {id:"Active",value:"Active"},
        {id:"Inactive",value:"Inactive"},
        
      ]
    const AddNew=()=>{
        navigate("/Admin/BankForm/0")
    }
    const ConfirmActionResultApprove=(e)=>{
       
      if(e==="Y"){
        let data={
          ID:currentID
        }
        axios({
          method:"post",
          url:CONST_URL_GET_DEACTIVATE,
          data:data

        })
        .then(function(ex){
          
          if(ex.data=="SUCCESS"){
            setefreshGridSource(prev=>prev+1);
          }

        })
        .catch(function(ex){

        })
      }
    }
    
    const onEdit=(e)=>{
      navigate("/Admin/BankForm/"+e);
    }
    const onDelete=(e)=>{
      setCurrentID(e);
      setShowMessageConfirmApprove(prev=>prev+1);

    }
  return (
    <>
    
     <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to deactivate?"} /> 
   
   <div style={{marginLeft:"15px",marginBottom:"10px",marginTop:"10px"}}>
       <h5>Bank List</h5>
 <Divider />
    
   <Button variant="primary" size="sm" onClick={AddNew}  >
   Add New
   </Button>

    
   </div>
    <Grid 
     DataSource={CONST_URL_GET_LIST} 
     ColumnList={ColumnList} 
     uniqueID="ID" 
     onEdit={onEdit}       
     onDelete={onDelete}
     refreshGrid={refreshGridSource}       
     filter={statusFilterlist}
     LinkColumn="AccountName"
     DefaultStatus="All"
   />
    </>
  )
}
