import React from 'react'
import Grid from '../Component/Grid'
import { Container,Row,Col } from 'react-bootstrap';
import { Divider } from 'primereact/divider';

export default function Schedule() {
  const CONST_URL_SCHEDULE ="Api/ScheduleApi/GetScheduleListPaginated"
  const ColumnListSchedule=[
    {FieldID:'Status', Label: 'Status'},
    {FieldID:'DateFrom', Label: 'From'},
    {FieldID:'DateTo', Label: 'To'},
    {FieldID:'CenterName', Label: 'Venue'},
    {FieldID:'Institution', Label: 'Institution'},
    {FieldID:'MaxNo', Label: 'Max'}
  ];
  const RegStyle={
    CardStyle:{
        marginTop: '50px'
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }

 }
  return (
    <>

    <Container  >
    <div className='card'>           
 
            <div className='cardContent'>
                <div style={{margin:"10px"}}>
                <h5>Schedule</h5>
                <Divider/>
            <Grid 
            DataSource={CONST_URL_SCHEDULE} 
            ColumnList={ColumnListSchedule} 
            uniqueID="SchedID"      
            filter=''      
            DefaultStatus='Active'
            
            />
            </div>
        </div>
        </div>
    </Container>
    </>
  )
}
