import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container } from 'react-bootstrap'
import NavbarAdmin from '../../Component/NavbarAdmin'
export default function AdminLayout() {
  return (
   <>
   <NavbarAdmin />

   <div className='container-fluid'>
        <div className='card'>           
            <div className='cardContent'>
            <Outlet/>
            </div>
          </div>
          </div>
   
   </>
  )
}
