import React, { useRef, useState } from 'react'
import { Container,Row,Col,Form, Button, Alert } from 'react-bootstrap'
import axios from 'axios';

import 'react-toastify/dist/ReactToastify.css';
 
 
function ForgetPassword() {
    const CONST_URL_FORGET= "Api/RegistrationApi/ForgetPassword";
    const formEmailAdd=useRef('');
    const [disableButton,setDisableButton]=useState(false);
    const RegStyle={
        CardStyle:{
         
        },RegFormStyle:{
            backgroundColor: 'white',
            padding: '30px'
    
        },CardImage:{
            height:'10% !important'
    
        },liStyle:{
            fontSize:'1.5rem'
        }
    
     }

     function FormSubmit(e){
        e.preventDefault();
        let formData='';
        formData={
            EmailAddress:formEmailAdd.current.value,            
        }
        if(formData.EmailAddress==''){
            alert('Please input your email address');
            return;
        }
        setDisableButton(true);
        axios({
            method: 'post',
            url: CONST_URL_FORGET,
            data: formData
          })
          .catch(function(ex){
            alert(ex);
          })
          .then(function (response) {
            
             if(response.data=="SUCCESS"){
                alert('Please check your email to retrieve your password');                                          
             }else  if(response.data=="EMAIL_NOT_EXIST"){
                alert('Unable to retreive your account. Please check your email address');                                          

             }else{
                alert(response.data);
             }
          })
         .finally(function(ex){
            setDisableButton(false);
         })
        
     };

  return (
    <>
   
    <div className='container'>
        <div className='row' style={{marginTop:"10px"}}>
            <div className='col-lg-2'></div>
                <div className='col-lg-8'>
                    <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                        <h4>Forget Password</h4>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="4">Email Address</Form.Label>
                        <Col sm="8">
                        <Form.Control type="text"   ref={formEmailAdd} autoComplete='off' />
                        </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                        <Col sm="12" style={{textAlign:'center'}}>
                        <Button  disabled={disableButton}  type='submit' variant='primary'>Proceed</Button>
                        </Col>
                        </Form.Group>
                </Form>        
    </div>       
    </div>
    </div>
    </>
  )
}

export default ForgetPassword
