import React from 'react'
import { Outlet } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import NavbarPublic from '../../Component/NavbarPublic'
export default function AdminLoginLayout() {
  return (
    <>
     <NavbarPublic />
 
    <Container style={{marginTop:"2%"}}> 
        
             <Outlet/>
           
           </Container>
    
    </>
  )
}
