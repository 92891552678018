import React from 'react'

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from '../Asset/Images/Logo/logo.png';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { useNavigate } from 'react-router-dom';
export default function NavbarCustomer() {
  const toast = useRef(null);
  const navigate=useNavigate();
  const accept = () => {
    localStorage.clear();
    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Thank you, have a nice day', life: 3000 });
    navigate('/')
};

const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
};

const confirm1 = (event) => {
    confirmPopup({
        target: event.currentTarget,
        message: 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        defaultFocus: 'accept',
        accept,
        reject
    });
};
 
  return (
    <>
    <Toast ref={toast} />
    <ConfirmPopup />
    <Navbar expand="lg" className="bg-body-tertiary">
     <Container>
       <Navbar.Brand href="#"><img src={logo} style={{height:"40px"}} /> PSY Systems & Innovations, OPC</Navbar.Brand>
       <Navbar.Toggle aria-controls="basic-navbar-nav" />
       <Navbar.Collapse id="basic-navbar-nav">
         <Nav className="me-auto">
           <Nav.Link href="/Customer/CustomerDashboard">Home</Nav.Link>           
           <Nav.Link href="/Customer/SubmitPayment">Submit Payment</Nav.Link>  
           <Nav.Link href="/Customer/CustomerTransaction">Transaction</Nav.Link>  
           <Nav.Link href="/Customer/CustomerEditAccount">Edit Account</Nav.Link>      
           <Nav.Link href="#" onClick={confirm1} >Log-Out</Nav.Link>   
         </Nav>
         
       </Navbar.Collapse>
     </Container>
   </Navbar>
  </>
 )
}
