import axios, { Axios } from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
export default function ActivateAccount() {
  const {UserID}=useParams();
  const navigate=useNavigate();
  const CONST_ACTIVATE_URL="Api/RegistrationApi/AcctivateAccount"
  const [status,setStatus]=useState('Activating Please wait...');

  useEffect(()=>{
    let data={
        UserID:UserID
    }
    axios({
        method:"post",
        data:data,
        url:CONST_ACTIVATE_URL
    })
    .then(function(ex){
        if(ex.data=="SUCCESS"){
            setStatus("Your account is now activate");    
            navigate("/");
        }else{
            setStatus("Unable to activate account");
        }
    })
  },[])
  return (
    <>
    <div>{status}</div>
    </>
    

  )
}
