import React, { useRef } from 'react'
import { Container,Col,Row,Form, Button } from 'react-bootstrap'

const CONST_URL_PAYMENT =process.env.REACT_APP_BACKEND_PRINTING_URL + "/View/viewer.aspx";

const RegStyle={
    CardStyle:{
        marginTop: '5px'
    },RegFormStyle:{
        backgroundColor: 'white',
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }
  
 }

export default function ReportMasterlist() {
  const refType=useRef('');
  const refExport=useRef('');
  const onPrintClick=()=>{
    let url=CONST_URL_PAYMENT + "?Type=Masterlist&&Filter="+ refType.current.value + "&&ExportType=" + refExport.current.value;
    window.open(url, '_blank', 'noopener,noreferrer');
}
  return (
    <>
     
    <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle}>
                    <h4>Masterlist Report</h4>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Filter</Form.Label>
                        <Col sm="4">
                           <Form.Select  ref={refType} >
                            <option value={"All"}>All</option>
                            <option value={"WithPayment"}>With Payment</option>
                            <option value={"Unregistered"}>Unregistered</option>
                           </Form.Select>
                        </Col>
                     </Form.Group>
                     <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="2">Export</Form.Label>
                        <Col sm="4">
                           <Form.Select  ref={refExport} >
                          
                            <option value={"PDF"}>PDF</option>
                            <option value={"XLS"}>Excel</option>
                           </Form.Select>
                        </Col>
                     </Form.Group>
                    
                     <Form.Group as={Row} className="mb-3">
                     <Col sm="6" style={{textAlign:"center"}}>
                            <Button variant="primary" onClick={onPrintClick}>Print</Button>
                     </Col>
                     </Form.Group>
                </Form>
            </Col>
        </Row>
    </Container>
                    


    </>
  )
}
