import {React,useEffect,useRef, useState} from 'react'
import { useParams } from 'react-router-dom'
import { useMountEffect } from 'primereact/hooks';
import { Alert, Container,Form,Row,Col, Button } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import congratsImage from "../../Asset/Images/48/information.png";
import { Fieldset } from 'primereact/fieldset';
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import CustomerInfo from '../../Component/CustomerInfo';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useReactToPrint } from "react-to-print";
import axios from 'axios';

export default function PaymentSuccess() {
  const [RefID,setRefID]=useState('');
  const URL_GET_PAYMENT="Api/PaymentApi/GetPaymentByEncrypted"
  const CONST_URL_PRINT=process.env.REACT_APP_BACKEND_PRINTING_URL + "/View/Viewer.aspx"
  const msgs = useRef(null);

  const [schedDate,setSchedDate]=useState('');
  const [venue,setVenue]=useState('');
  const [paymentReferenceNo,setPaymentReferenceNo]=useState('');
  const [datePaid,setDatePaid]=useState('');
  const [amt,setAmt]=useState('');
  const [referenceNo,setReferenceNo]=useState('');
 
  const [showStatus,setShowStatus]=useState(false);
  const componentRef = useRef();
  useMountEffect(() => {
      if (msgs.current) {
          msgs.current.clear();
          msgs.current.show({ id: '1', sticky: true, severity: 'info', summary: 'Info', detail: 'Message Congratulations You Have Successfully Reserved Your Slot In the Schedule.', closable: false });
      }
  }); 
  useEffect(()=>{
    
    setRefID(localStorage.getItem("refno"));
    
    var param={
        ReferenceNo:localStorage.getItem("refno")
    }
    axios({
        method:"post",
        url: URL_GET_PAYMENT,
        data:param
    })
    .then(function (res) {  
        // SetGridData(response.data);
        var data = res.data[0];
         
        setSchedDate(data.ScheduleDate);
        setVenue(data.Venue);
        setPaymentReferenceNo(data.PaymentReferenceNo);
        setDatePaid(data.DatePaid);
        setAmt(data.Amt);
        setReferenceNo(data.ReferenceNo);
        
        if(data.Status=="Requested"){
            setShowStatus(true);
            
        }else{
            setShowStatus(false);
            
        }
     })
     .catch(function (error) {                
         console.log("Error" +error);
     })
     .finally(function () {
         // always executed
     });

  },[])
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  return (
    <>
    <Container>   
        <div className='card' ref={componentRef}>           
            <div className='cardContent'>
               
                <div style={{marginTop:"20px",textAlign:"center"}}>                    
                <Messages ref={msgs} />
                <h4  style={{marginBottom:"0px"}} className="headerFontName">Psych Systems and Innovations, OPC</h4>
                <label className="headerFontName">Your development is our achievement!</label><br></br>
                <label>
                3F, Framar Center, 111 A. Mabini St., Brgy. Kapasigan, Pasig City, NCR, Philippines, 1700</label><br></br>
                <label>Tel Nos: (02) 475-8710 /(02) 7373-4406 Telefax: (02) 706-6493 Website: www.psi-services.net</label>

                
                </div>

              
                
                <Fieldset >
                    <legend style={{textAlign:"center"}} >Philippine Merchant Marine Academy <br></br>Psychological Evaluation<br></br><b>Test Permit</b></legend>
                    {showStatus?(
                    
                    <h6 style={{color:"red"}}>
                        Note: PSI will review your submitted credentials within three days. Upon receipt of the confirmation, 
                        <br></br>
                        please print and bring the test permit on the day of your examinatìon.                                        
                    </h6>
                    
                    ):''}
                    <h6 style={{fontWeight:"600",color:"red"}}>Transaction Ref. No:{referenceNo}</h6>
                    <h4 style={{color:"#28a745"}}>Personal Information</h4>
                    <hr style={{marginTop:"0px"}}></hr>
                    <CustomerInfo/>
                    <h4 style={{color:"#28a745",marginTop:"20px"}}>Schedule of Examination</h4>
                    <hr style={{marginTop:"0px"}}></hr>

                    <Form.Group as={Row} className="mb-12">
                        <Col className='labelFontWeight'  sm="2">Date of Testing</Col>
                        <Col   sm="2">{schedDate}</Col>           
                    </Form.Group>
                    <Form.Group as={Row} className="mb-12">
                        <Col className='labelFontWeight'  sm="2">Venue</Col>
                        <Col   sm="2">{venue}</Col>           
                    </Form.Group>


                    <h4 style={{color:"#28a745",marginTop:"20px"}}>Payment Details</h4>
                    <hr style={{marginTop:"0px"}}></hr>
                    <Form.Group as={Row} className="mb-12">
                        <Col className='labelFontWeight'  sm="2">Bank Transaction No</Col>
                        <Col   sm="2">{paymentReferenceNo}</Col>           
                    </Form.Group>
                    <Form.Group as={Row} className="mb-12">
                        <Col className='labelFontWeight'  sm="2">Payment Date</Col>
                        <Col   sm="2">{datePaid}</Col>           
                    </Form.Group>
                    <Form.Group as={Row} className="mb-12">
                        <Col className='labelFontWeight'  sm="2">Payment Amount</Col>
                        <Col   sm="2">{amt}</Col>           
                    </Form.Group>

                   
                    <h4 style={{color:"#28a745",marginTop:"20px"}}>Guidelines</h4>
                    <hr style={{marginTop:"0px"}}></hr>
                    <Form.Group as={Row} className="mb-12">
                        <Col   sm="12">1.   Please bring this test permit on the day of your examination.</Col>                                
                        <Col   sm="12">2.	Attach 2x2 ID picture with name tag in white background.</Col>
                        <Col   sm="12">3.	Wear white t-shirt/poloshirt, manong pants, and rubber shoes on the examination day.</Col>
                        <Col   sm="12">4.	Bring with a valid ID.</Col>
                        <Col   sm="12">5.	Bring 2 pencils (lead no.2),  eraser,  sharpener.</Col>
                        <Col   sm="12">6.	Bring snacks (to be consumed outside of the testing room).</Col>
                        <Col   sm="12">7.	No cellphone is allowed upon entry in the testing room.</Col>
                        
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
             <Form.Label column sm="2"></Form.Label>
                <Col sm="12" style={{textAlign:"center"}}>
                
                   <Button style={{display:"none"}}  onClick={() => openInNewTab(CONST_URL_PRINT +"?Ref="+RefID+"&&Type=PaymentReceipt")}  variant="primary">Print</Button>
                </Col>
             </Form.Group>
                </Fieldset>
            </div>
        </div>
        <Button onClick={handlePrint}>Print</Button>
    </Container>
    {RefID}
    </>
  )
}
