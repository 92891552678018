import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Modal,Row,Col,Form, Alert,Button,Container } from 'react-bootstrap';
import DropdownInstitution from '../../Component/DropdownInstitution';
export default function EditPermit(props) {
  const CONST_URL_EDIT="Api/PermitApi/GetInfo";
  const CONST_URL_UPDATE="Api/PermitApi/Update";
  const [showModal,setShowModal]=useState(false);
  const [showAlert,setshowAlert]=useState(false);
  const [errorMessage,setErrorMessage]=useState('');
  const [id,setID]=useState(0);
  const refPermitNo=useRef();
  const refName=useRef();
  const [state,setState]=useState({Name:'',PermitNo:''});
  const [insValue,setInsValue]=useState(0);
  


  useEffect(()=>{
    if(props.addNew>0){
        setState({            
            Name:'',
            PermitNo:''
          })
          setID(0);
        setShowModal(true);      
    }
  },[props.addNew])
  useEffect(()=>{
    if(props.setID>0){                           
     //   setShowModal(true);      
     GetInfo();        
    }
  },[props.onShowModal])
  const handleClose=()=>{
    setShowModal(false);
  }
  const saveForm=()=>{
   
    if(refPermitNo.current.value==''){
        setshowAlert(true);
        setErrorMessage("Please input permit no.")
    }else if(refName.current.value==''){
        setshowAlert(true);
        setErrorMessage("Please input name")
    }else if(insValue==0){
      setshowAlert(true);
      setErrorMessage("Please select institution name")
    
    }else{
        let data={
            PermitNo:refPermitNo.current.value,
            Name:refName.current.value,
            ID:props.setID,
            insID:insValue
        }
        axios({
            method:"post",
            url:CONST_URL_UPDATE,
            data:data
        })
        .then(function(rs){
          
            if(rs.data=="SUCCESS"){
                setshowAlert(false);
                setErrorMessage("")
                setShowModal(false);
                props.onRefreshGrid();
            }
        })
        .catch(function(ex){
            alert(ex);
        })
    }
  } 
  const GetInfo=()=>{
    
    let data={
        ID:props.setID
    }
    axios({
        method: "post",
        url:CONST_URL_EDIT,
        data:data
    })
    .then(function(rs){
      console.log("Name " + rs.data[0].Name);
      setState({            
        Name:rs.data[0].Name,
        PermitNo:rs.data[0].PermitNo,
        insID:rs.data[0].insID
      })     
      setShowModal(true);
    })
    .catch(function(ex){
      alert(ex);
    })
    .finally(function(){
    
    })
    
        //alert(refName.value);
       // alert(rs.data[0].Name);
     
       // alert(state.Name);
   
 }
 const currentInstitutionValue=(e)=>{
  setInsValue(e);
 }
   
  return (
    <>
    
      <Modal   size="lg" show={showModal} >
        <Modal.Header>
          <Modal.Title>Permit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>            
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Permit No</Form.Label>
                        <Col sm="8">
                            <Form.Control type='text' defaultValue={state.PermitNo} ref={refPermitNo} ></Form.Control>
                        </Col>
                    </Form.Group>       
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Institution</Form.Label>
                        <Col sm="8">
                          <DropdownInstitution setDefValue={state.insID} setValue={e=>currentInstitutionValue(e)} ></DropdownInstitution>
                          
                        </Col>
                    </Form.Group>       
                    <Form.Group as={Row}>
                        <Form.Label column sm="4">Name</Form.Label>
                        <Col sm="8">
                            <Form.Control type='text' defaultValue={state.Name} ref={refName} ></Form.Control>
                        </Col>
                    </Form.Group>     
                    
                    <Form.Group as={Row}>
                      <Col sm="12">
                        <Alert variant='danger' show={showAlert} style={{marginTop:"10px"}}>{errorMessage}</Alert>
                      </Col>
                    </Form.Group>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveForm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
