import React, { useRef, useState } from 'react'
import Grid from '../../Component/Grid'
import Messagebox from '../../Component/Messagebox';
import axios from 'axios';
import EditPermit from '../../Pages/Admin/EditPermit';
import { Button } from 'react-bootstrap';
export default function ViewPermitList() { 
   const CONST_URL_LIST=  "Api/PermitApi/GetPermitList";    
    const CONST_URL_DELETE="Api/PermitApi/DeletePermit";
    const [showMessageConfirmApprove,setShowMessageConfirmApprove]=useState(0);
    const [refreshGridSource,setRefreshGridSource]=useState(0);
    const [id,setID]=useState(0);
    const [onOpenModal,setOnOpenModal]=useState(0);
    const [counter,setCounter]=useState(0);
    const ColumnList=[
        {FieldID:'ID', Label: 'ID'},
        {FieldID:'InsName', Label: 'Institution'},        
        {FieldID:'PermitNo', Label: 'Permit No'},
        {FieldID:'Name', Label: 'Name'},
        {FieldID:'DateAdded', Label: 'Date Added'},
        {FieldID:'UploadedBy', Label: 'Uploaded By'},        
        {FieldID:'Edit', Label: 'Edit'},
        {FieldID:'Delete', Label: 'Delete'},
      ];
    
    const onEdit=(id)=>{
      setID(id);
      setOnOpenModal(prev=>prev+1);
    

    }
    const onDelete=(id)=>{
      setID(id);
      setShowMessageConfirmApprove(prev=>prev+1);
    
    }
    const ConfirmActionResultApprove=(e)=>{
      if(e=="Y"){
        let data={
          ID:id
        }
        axios({
          data:data,
          url:CONST_URL_DELETE,
          method:"post"
        })
        .then(function(ex){
          
          setRefreshGridSource(prev=>prev+1);
        })
        .catch(function(ex){
          alert("Something went wrong");
        })
  
      }
    }
    const RefreshGrid=()=>{
      setRefreshGridSource(prev=>prev+1);
    }
    const AddNew=()=>{
      setCounter(prev=>prev+1);
    }
  return (
    <>
    <Messagebox  result={ConfirmActionResultApprove} showMessagebox={showMessageConfirmApprove}  Header={"Confirmation"} Content={"Are you sure you want to delete?"} /> 
    <EditPermit addNew={counter} onShowModal={onOpenModal} setID={id}  onRefreshGrid={RefreshGrid}  />
    <div style={{margin:"10px"}}>
    <h5 style={{marginLeft:"10px"}}>Permit List</h5>
    
    <Button variant="primary" size="sm" style={{marginLeft:"10px",marginBottom:"10px"}} onClick={AddNew}  >
     Add New
      </Button>
    <Grid 
      DataSource={CONST_URL_LIST}       
      ColumnList={ColumnList} 
      uniqueID="ID"    
      onEdit={onEdit}
      refreshGrid={refreshGridSource}
      onDelete={onDelete}             
      filter=''     
      DefaultStatus=""
    />
  
    </div>
    </>
  )
}
