import React, {  useRef,useContext } from 'react'
import { Alert, Col,  Form, Row } from 'react-bootstrap'
import { useState } from 'react';
import Papa from 'papaparse';
import Button from "react-bootstrap/Button";
import axios, { formToJSON } from 'axios';
import { DataTable } from  "primereact/datatable"
import { Column } from "primereact/column";
import { LoginContexts } from '../../Contexts/LoginContexts';
import MessageboxToast from '../../Component/MessageboxToast';
export default function UploadMember() {
    const CONST_UPLOAD_URL="Api/UserApi/UploadMasterList";
    const[csvData,setCsvData]=useState([]);
    const allowedExtensions = ["csv"];
    // This state will store the parsed data
    const [data, setData] = useState([]);
    // It state will contain the error when
    // correct file extension is not used
    const [error, setError] = useState("");
    const [showError,setShowError]=useState(false);

    // It will store the file uploaded by the user
    const [file, setFile] = useState("");    
    const txtValue=useRef();    
    const {username} =useContext(LoginContexts);
    const [showToast,setShowToast]=useState(0);
    const [btnUpload,setBtnUpload]=useState(false);
    function insertdb()
    {   
        setBtnUpload(true);
        axios({
            method: 'post',
            url: CONST_UPLOAD_URL,
            data: JSON.parse(txtValue.current.value)
        })
        .then(function (response) {
            setShowToast((prev)=>prev+1);
        })
        .catch(function(ex){

        })
        .finally(function(ex){
            setBtnUpload(false);
        })

    }
    const handleFileChange = (e) => {
        console.log(e);
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
 
            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension =
                inputFile?.type.split("/")[1];
            if (
                !allowedExtensions.includes(fileExtension)
            ) {
                setError("Please input a csv file");
                return;
            }
 
            // If input type is correct set the state
            setFile(inputFile);
        }
        
        }
        const handleParse = () => {
     
            
           
            // If user clicks the parse button without
            // a file we show a error
            if (!file) return alert("Enter a valid file");
     
            // Initialize a reader which allows user
            // to read any file or blob.
            const reader = new FileReader();
     
            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload =  ({ target }) => {                
                const csv = Papa.parse(target.result, {
                    header: false,
                    complete: (result) => {                      
                      var rows = result.data;
                      for (var i = 0; i < rows.length; i++){
                         let permitNo=rows[i][0];
                         let name=rows[i][1]
                         if(permitNo!=undefined){                                               
                         setCsvData(csvData =>[...csvData,{ Name:name, PermitNo: permitNo,UploadedBy:username } ]);                                                     

                         }
                         
                    }
              
                 
                       }
                       
                });

              
             
              
            
            };

      

            reader.readAsText(file);
            
        };
     
  return (
    <>
  <MessageboxToast ShowToast={showToast} />
    <div style={{margin:"10px"}}>
    <Form  >
        <h4>Upload Permit</h4>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
            <Form.Label column sm="2">Upload csv file</Form.Label>
            <Col sm="4">
                <Form.Control type="file" id="file-input-id" name="file"  onChange ={handleFileChange} />
            </Col>
            <Col sm="2">
                <Button variant='primary' onClick={handleParse} >Parse</Button>
                <Button variant='primary' style={{marginLeft:"5px"}}  onClick={insertdb}  disabled={btnUpload} >Save</Button>
            </Col>
        </Form.Group>   
        <Form.Group as={Row} className="mb-3">
            <Col sm="12" style={{textAlign:'center'}}>
                <Alert show={showError}  variant="danger"></Alert>
            </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
            <Col sm="12" style={{textAlign:'center'}}></Col>
        </Form.Group>
    </Form>   
    <Form.Group as={Row} className="mb-3">
        <Col sm="12" style={{textAlign:'center'}}>
            <DataTable value={csvData} 
                stripedRows 
                size="small"
                paginator    
                emptyMessage="No data found"                
                rowsPerPageOptions={[5, 10, 25, 50]}
                rows={50}
                showGridlines            
                >                
                <Column field="Name" sortable header="Name"></Column>   
                <Column field="PermitNo" sortable header="PermitNo"></Column>                   
                <Column field="UploadedBy" sortable header="Uploaded By"></Column> 
            </DataTable>
        </Col>
    </Form.Group>
    </div>
    <input hidden ref={txtValue} value={JSON.stringify(csvData)}  />



    
    
    </>
    
  )
}
