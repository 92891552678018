import React, { useEffect, useRef, useState,useContext } from 'react'
import { Table } from 'react-bootstrap';
import ReactPaginate from "react-paginate";
import axios from 'axios';
import { LoginContexts } from '../Contexts/LoginContexts';
import { useDownloadExcel } from 'react-export-table-to-excel';
export default function Grid(props) {
    const [page,setPage]=useState(1);
    const [items,setItem]=useState([]);
    const [pageCount,setPageCount]=useState(0);
    const [displayItem,setDisplayItem]=useState(10);
    const [searchValue,setSearchValue]=useState('');
    const [filterCount,setFilterCount]=useState(false);
    const [filterList,setFilterList]=useState([]);
    const [selectedStatus,setSelectedStatus]=useState(props.DefaultStatus);
    const url=props.DataSource;
    const ListColumn=props.ColumnList;
    const uniqueID=props.uniqueID;
    const refSearchValue=useRef();
    const refStatusFilter=useRef();
    const linkColumn=props.LinkColumn;
    const [showAddButton,setShowAddButton]=useState(props.ShowAddButton)
    const tableRef = useRef(null);
    const {accountType}=useContext(LoginContexts);

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: 'Download',
      sheet: 'Sheet1'
  })

    const onPageChange=((e)=>{    
      setPage(e.selected+1);
    })
    const onChangeDisplay=(e=>{    
      setDisplayItem(e.target.value);
    })
    var data={
      Display:displayItem,
      CurrentPage:page,
      SearchValue:searchValue,
      TransStatus:selectedStatus
    }
    if(props.filterByInstitution){
      //data.push("Institution",props.filterByInstitution);
        data.Institution = props.filterByInstitution;
    }
    if(props.setFilterPerUser){
      data.UserID=props.setFilterPerUser
    }
   
    
    useEffect(()=>{
      
     if(props.filter.length>0){
        setFilterCount(true);
        setFilterList(props.filter);
      }
      
      axios({
          method:"post",
          data:data,
          url:url
      })                       
      .then(function (response) {
          
          setPageCount(response.data[0].PageCount);  
          setItem(response.data);
          
      })
      .catch(function (error) {                
          console.log("Error" +error);
      })
      .finally(function () {
          // always executed
      });
  
    },[page,displayItem,searchValue,props.refreshGrid,selectedStatus])

    function FindItem(){
        setSearchValue(refSearchValue.current.value);
    }

    const EditForm=(id)=>{    
        
        props.onEdit(id);
      }
    const LinkColumn=(id)=>{
        props.onLinkColumn(id);
    }
    
    const RejectForm=(id)=>{
      props.onReject(id);
    }
    const DeleteForm=(id)=>{
        props.onDelete(id);
    }
    const selectedStatusChange=()=>{
        setSelectedStatus(refStatusFilter.current.value);
    }
    return (
      <>
  
      
      
        {showAddButton?(  
        <div className='row'>
            <div className='col-lg-1'>
              <button   variant="primary"  style={{marginBottom:"10px"}}  className="input-group-text btn btn-primary btn-sm" id="btnAdd">
                
                Add</button>
            </div>   

        </div>):''
        }
        <div className='row'>    
          
        {filterCount?( 
            <div className='col-lg-2' style={{display:accountType!="Admin"?"none":""}}>
                <div className="input-group mb-3">        
                    <div className="input-group-prepend">
                        <label className="input-group-text" >Status</label>
                    </div>
                        <select className="form-select" aria-label="Select" onChange={()=>selectedStatusChange()} ref={refStatusFilter}>  
                        {filterList.map((list) => (
                            <option key={list.id} value={list.id}>{list.value}</option>  
                        ))}               
                        
                        </select>
                </div>       
            </div>
        ):''}
        <div className='col-lg-6' style={{textAlign:"right",display:accountType!="Admin"?"none":""}}>
            <div className="input-group mb-3">        
                <div className="input-group-prepend">
                    <label className="input-group-text" >Search</label>
                </div>
                <input type="text" className="form-control" placeholder="Name" ref={refSearchValue} aria-describedby="btnFind"/>
                <button   variant="primary" onClick={()=>FindItem()} className="input-group-text btn btn-warning" id="btnFind">Find</button>
                <button   variant="primary"  onClick={onDownload} className="input-group-text btn btn-primary" id="btnExport">Export</button>
            </div>            
        </div>
      </div>      
      {!items?"<div>loading..</div>":""}
      <Table striped bordered hover size="sm" responsive  ref={tableRef}>
        <thead >
          <tr >
          {ListColumn.map((col) => (
              <td style={{backgroundColor:"#0d6efd96",fontWeight:600}}>{col.Label}</td>
          ))}        
          </tr>
        </thead>
        <tbody>
          {items.map((item,index)=>{
              
              return <tr key={index}>            

            {ListColumn.map((col) => {
              return (
                <td>
                    {
                    col.FieldID==linkColumn?<a onClick={()=>LinkColumn(item[uniqueID])} href='#'>{item[col.FieldID]}</a>:
                    item[col.FieldID]=="Delete"?<a onClick={()=>DeleteForm(item[uniqueID])} href='#'>{col.Label}</a>:
                    item[col.FieldID]=="Reject"?<a onClick={()=>RejectForm(item[uniqueID])} href='#'>{col.Label}</a>:
                    item[col.FieldID]=="Edit"?<a onClick={()=>EditForm(item[uniqueID])} href='#'>{col.Label}</a>:item[col.FieldID]
                    }
                </td>                  
                
              );
            })}                           
          </tr>      
          })}
        </tbody>
      
      </Table>
  
    
  
      <div className='row'>
      <div className='col-sm-2'>
  
      <div className="input-group mb-2">
    <div className="input-group-prepend">
      <label className="input-group-text" for="inputGroupSelect01">Show</label>
    </div>
    <select defaultValue={10} className="form-select" id="inputGroupSelect01" onChange={onChangeDisplay}>
      <option selected defaultValue={10}>10</option>
      <option value="20">20</option>
      <option value="30">30</option>
      <option value="40">40</option>
      <option value="6000">All</option>
    </select>
  </div>
  
      </div>
    
      <div className='col-md-6'>
      <ReactPaginate
      previousLabel={'Prev'}
      nextLabel={'Next'}
      breakLabel={'...'}
      pageCount={pageCount}
      pageRangeDisplayed={3}
      onPageChange={onPageChange}
      containerClassName={'pagination justify-content-left'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      activeClassName='active'
      >
      </ReactPaginate>
      </div>
      </div>

      </>
    )
}
