import React, { useEffect, useRef, useState } from 'react'
import { Container,Row,Col,Form, Button, Alert } from 'react-bootstrap'
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from "validator";
import { useContext } from 'react';
import { LoginContexts } from '../../Contexts/LoginContexts';
import DropdownInstitution from '../../Component/DropdownInstitution';
import {useParams,useNavigate} from "react-router-dom";

export default function MemberRegistration() {
    const[errMessage,setErrMessage]=useState('');
    const[alertType,setAlertType]=useState('danger');
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const formUserName=useRef('');
    const formAddress=useRef('');
    const formGender=useRef('')
    
    const formBdate=useRef('');
    const formContactNo=useRef('');
    const formEmailAdd=useRef('');
    const passMinLevel=useRef('');
    
    const formPermitNo=useRef('');
    const formStatus=useRef('');
    const [showAlert,setShowAlert]=useState(false);
    const formName=useRef('');
    const formPassword=useRef('')
    const formRePassword=useRef('');
    const formNationality=useRef('')
    const [disableButton,setDisableButton]=useState(false);
    const CONST_URL_REGISTER= "Api/RegistrationApi/Register";
    const CONST_URL_REGISTER_EDIT= "Api/UserApi/GetMemberInfo";
    const {username} =useContext(LoginContexts);
    const [insValue,setInsValue]=useState(0);
    const {ID}=useParams();
    const [memgender,setMemGender]=useState('');
    const [status,setStatus]=useState('');
    const [institution,setInstitution]=useState(0);
    const navigate=useNavigate();
    useEffect(()=>{
        if(ID>0){
            let data={UserID:ID}
            axios({
                url:CONST_URL_REGISTER_EDIT,
                data:data,
                method:"post"
            })
            .then(function(res){
                formName.current.value=res.data[0].Name;
                formAddress.current.value=res.data[0].Address;
                formBdate.current.value=res.data[0].BirthDate;               
                formContactNo.current.value=res.data[0].ContactNo;   
                formNationality.current.value=res.data[0].Nationality;  
                formEmailAdd.current.value=res.data[0].EmailAddress;  
                formPermitNo.current.value=res.data[0].PermitNo;  

                formUserName.current.value=res.data[0].UserName;  
                formPassword.current.value=res.data[0].UserPassword;  
                formRePassword.current.value=res.data[0].UserPassword;  
                setInstitution(res.data[0].insID);
                setMemGender(res.data[0].Gender);
                setStatus(res.data[0].Status);
                
            })
            .catch(function(ex){

            })
        }
    },[ID])

    function FormSubmit(e){
        e.preventDefault();
      //  console.log(process.env);
      //  alert(process.env.REACT_APP_REGISTRATION_API);
        let formData='';
        formData={
            UserID:ID,
            Name: formName.current.value,
            Address: formAddress.current.value,            
            BirthDate: formBdate.current.value,
            ContactNo:formContactNo.current.value,
            EmailAddress:formEmailAdd.current.value,
            PermitNo:formPermitNo.current.value,
            UserName:formUserName.current.value,
            UserPassword:formPassword.current.value,
            RePassword:formRePassword.current.value,
            Nationality:formNationality.current.value,
            Gender:formGender.current.value,
            Type:"W",
            UploadedBy:username,
            insID: insValue,
            Status: formStatus.current.value,
            CreatedBy:username

        }
        
        
       
        if(formData.Name==''){
            setErrMessage('Please input name');
            setShowAlert(true);
        }else  if(formData.Address==""){
            setErrMessage('Please input address');
            setShowAlert(true); 
        }else  if(formData.BirthDay==""){
            setErrMessage('Please select birthday');
            setShowAlert(true);
        }else if(formData.ContactNo==""){
            setErrMessage('Please input contact no');
            setShowAlert(true);
        }else if(formData.Nationality==""){
            setErrMessage('Please input nationality');
            setShowAlert(true);
        }else if(formData.Gender==""){
            setErrMessage('Please select gender');
            setShowAlert(true);
        }else if(formData.EmailAddress==''){
            setErrMessage('Please input email address');
            setShowAlert(true);
        }else if (!validator.isEmail(formData.EmailAddress)) {  
            setErrMessage('Please input valid email address');
            setShowAlert(true);     
        }else if(formData.PermitNo=='' && formData.insID>0){
            setErrMessage('Please input permit no.');
            setShowAlert(true);
        }else if(formData.Username==""){
            setErrMessage('Please input username');
            setShowAlert(true);
        }else if(formData.UserPassword==""){
            setErrMessage('Please input password');
            setShowAlert(true);
        }else if(passMinLevel.current.value=="Weak"){
            setErrMessage('The password you have selected is weak');
            setShowAlert(true);
        }else if(formData.RePassword==""){
            setErrMessage('Please input password confirmation');
            setShowAlert(true);
        }else if(formData.UserPassword!=formData.RePassword){
            setErrMessage('Password confirmation not match');
            setShowAlert(true);
        }else{
            SaveRegistration(formData);
        }  
     
            
    }
    function ClearFields(){
        formName.current.value='';
        formAddress.current.value='';        
        formBdate.current.value='';
        formContactNo.current.value='';
        formEmailAdd.current.value='';
        formPermitNo.current.value='';
        formUserName.current.value='';
        formPassword.current.value='';
        formRePassword.current.value=''
        
      
        

    }
    function SaveRegistration(myData){       
        setDisableButton(true);
        axios({
            method: 'post',
            url: CONST_URL_REGISTER,
            data: myData
          })
          .then(function (response) {
            setDisableButton(false);
             if(response.data=="SUCCESS"){
                setAlertType('success');
                setErrMessage('You have successfully registered new member!');
                setShowAlert(true);
                toast.success("You have successfully registered new member!", {
                    position: "top-center"
                  });
                  if(ID==0){
                    ClearFields();
                  }
             }else if(response.data=="PERMIT_EXIST"){
                setAlertType('success');
                setErrMessage('Your permit no. is not authorize to access the system');
                setShowAlert(true);

             }else{
                setAlertType('danger');
                setErrMessage('Please try again later.Maintenane is on-going');
                setShowAlert(true);
             }
          });

    }

 const RegStyle={
    CardStyle:{
        marginTop: '10px'
    },RegFormStyle:{
        backgroundColor: 'white',
        paddingTop:"10px",
        padding: '30px'

    },CardImage:{
        height:'10% !important'

    },liStyle:{
        fontSize:'1.5rem'
    }

 }
 const handlePassword = (passwordValue) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak";  

        setPassword(passwordValue);
     
        setMessage(strength);
    

     
    console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
  };
  const getActiveColor = (type) => {
    var ret="#FF0054";
    
    if (type === "Strong"){       
        return "#8BC926";
    } 
    if (type === "Medium"){
        return "#FEBD01";
    } 
    return "#FF0054";
  };
  const onChangeIns=(x)=>{
    setInsValue(x);
  }
  const BacktoList=()=>{
    
    navigate("../Member");

  }
  return (
    <>
    <ToastContainer />
    <Container  style={RegStyle.CardStyle} >
        <Row>
          
            <Col xs>
                <Form style={RegStyle.RegFormStyle} onSubmit={(e)=>FormSubmit(e)}>
                    <h4>Register</h4>
                    <hr></hr>
                    <Form.Group as={Row} className="mb-12" controlId="formPlaintextEmail">
                        
                       <Form.Group as={Row} className="mb-3">
                         <Form.Label column sm="2">Institution</Form.Label>
                            <Col sm="4">
                            <DropdownInstitution setDefValue={institution} setValue={(e)=>onChangeIns(e)}></DropdownInstitution>
                            </Col>
                            </Form.Group>


                            <Form.Group as={Row} className="mb-3" style={{marginTop:"10px"}} >
                            <Form.Label column sm="2">Status</Form.Label>
                            <Col sm="4">
                                <select class="form-select" aria-label="" ref={formStatus}>                                                               
                                <option  selected={status=='Active'?'Selected':''}  value="Active">Active</option>
                                <option selected={status=='Inactive'?'Selected':''}  value="Inactive">Inactive</option>                                
                                </select>
                            </Col>
                        </Form.Group>
                        
                       
                        <Form.Label column sm="2">Name</Form.Label>
                            <Col sm="4">
                                <Form.Control type="text" defaultValue="" ref={formName} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-10" style={{marginTop:"10px"}} >
                            <Form.Label column sm="2">Address</Form.Label>
                            <Col sm="8">
                                <Form.Control   as="textarea"  ref={formAddress}  autoComplete='off' placeholder="Address"></Form.Control>
                            </Col>
                        </Form.Group>                       
                        <Form.Group as={Row} className="mb-3" style={{marginTop:"10px"}} >
                            <Form.Label column sm="2">Birthdate</Form.Label>
                            <Col sm="4">
                            <Form.Control type="date"  ref={formBdate} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" style={{marginTop:"10px"}} >
                            <Form.Label column sm="2">Gender</Form.Label>
                            <Col sm="4">
                                <select class="form-select" aria-label="" ref={formGender}>                                                               
                                <option  selected={memgender=='Male'?'Selected':''}  value="Male">Male</option>
                                <option selected={memgender=='Female'?'Selected':''}  value="Female">Female</option>                                
                                </select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Contact No</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formContactNo} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Nationality</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formNationality} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">Email</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"   ref={formEmailAdd} autoComplete='off' />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">Permit No.</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formPermitNo}  autoComplete='off' />
                            </Col>
                        </Form.Group>                                                                
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">Username</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formUserName} autoComplete='off' />
                            </Col>
                        </Form.Group>      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">Password</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formPassword}   onChange={({ target }) => {
                  handlePassword(target.value);
                }} />
                            </Col>
                        </Form.Group>      
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                            <Form.Label column sm="2">Re-type Password</Form.Label>
                            <Col sm="4">
                            <Form.Control type="text"  ref={formRePassword} />
                            </Col>
                        </Form.Group>  

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                            {password.length !== 0 ? (
                                <p className="message" style={{ color: getActiveColor(message) }}>
                                Your password is {message}
                                </p>
                                ) : null}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Col sm="6" style={{textAlign:'center'}}>
                                <Alert show={showAlert} variant={alertType}>{errMessage}</Alert>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm="7" style={{textAlign:'center'}}>
                                <Button disabled={disableButton} type='submit' variant='primary'>Register</Button>
                                <Button onClick={()=>BacktoList()} style={{minWidth:"100px",marginLeft:"10px"}}  type='button' variant='primary'>Back</Button>
                            </Col>
                        </Form.Group>
                    
                     
                        <input type="hidden" value={message} ref={passMinLevel} ></input>
                    </Form>        
            </Col>
        </Row>
    </Container>

    </>
  )
}
