import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row,Form } from 'react-bootstrap'
import { useContext } from 'react'
import { LoginContexts } from '../../Contexts/LoginContexts'
import { Divider } from 'primereact/divider'
import SubmitPaymentStep1 from './SubmitPaymentStep1'

import submitPaymentImage from "../../Asset/Images/64/bill.png";
import { useNavigate } from 'react-router-dom'
import SubmitPamentStep2 from './SubmitPaymentStep2'


export default function SubmitPayment() {
  const [step2,setStep2]=useState("none");
  const [step1,setStep1]=useState("block");
  const [schedID,setSchedID]=useState(0);
  const ShowStep2=(SchedID)=>{
    setSchedID(SchedID);
    setStep2("block");
    setStep1("none");
  
  }
  const ShowStep1=()=>{
    setStep2("none");
    setStep1("block");
  
  }
  return (
    <>
  

  <div className='card' style={{margin:"20px"}}>           
    <div className='cardContent'>
      <div style={{margin:"20px"}}>
        <h4 style={{color:"#28a745"}}><img src={submitPaymentImage} style={{height:"48px"}} />  Submit Payment</h4>
        <Divider/>
        <div style={{display:step1}}>
        <SubmitPaymentStep1 ShowStep2={ShowStep2} ></SubmitPaymentStep1>
        </div>
        <div style={{display:step2}}>
        <SubmitPamentStep2 SelectedSchedID={schedID} ShowStep1={ShowStep1}></SubmitPamentStep2>
        </div>
      </div>
    </div>
  </div>

   </>
  )
}

