import React from 'react'
import CardImage from './../Images/adminlogin.png';
import { Card,Button } from 'react-bootstrap';

export default function PublicCard(props) {
    
    const RegStyle={
        CardStyle:{
            marginTop: '10px'
        },RegFormStyle:{
            
            padding: '30px'
    
        },CardImage:{
            width:"50%"
    
        },liStyle:{
            fontSize:'1.5rem'
        },RegButon:{
            display: props.showRegButton?'none':'solid'
        }
    }        

    
  return (
    <>
     <Card style={{borderStyle:"none"}} >
                    
                    <Card.Body style={{textAlign:"center",backgroundColor:"#f0f2f5"}}>
                        <Card.Title as='h5' >Welcome to PSI registration Platform.</Card.Title>
                        <Card.Img variant="top" src={CardImage}   style={RegStyle.CardImage}  />
                        
                    </Card.Body>
                </Card>
    </>
  )
}

