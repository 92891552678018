import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
export const RegistrationCount = () => {
  const CONST_URL='Api/RegistrationApi/GetCountSummary';
  const [name,setName]=useState([{}]);
  
  useEffect(()=>{
    axios({
     url:CONST_URL,
     method:'get'
    })
    .then(function(res){
     
       setName(res.data);
    })
    
   },[]);
  return (
    <div className="col-lg-4">
    <table className="table">
        <thead>
            <th>Institution</th>
            <th>Count</th>
        </thead>
     <tbody>
    { name.map((rs,index) =>
        <tr key={index}><td>{rs.Institution}</td><td>{rs.RegCount}</td></tr>
      )}
      </tbody>
  </table>
  </div>
  )
}
