import React, { useEffect, useRef } from 'react'
import MemberGrid from '../../Component/MemberGrid'
import { Button, Container,Form } from 'react-bootstrap'
import { Divider } from 'primereact/divider'
import {useNavigate} from "react-router-dom";

 
 
export default function Member() {
  const navigate=useNavigate();
  const AddNew=()=>{
    navigate("/Admin/MemberRegistration");
  }
  return (
    <>
    <div style={{marginLeft:"15px",marginBottom:"10px"}}>
      <h5 style={{marginTop:"10px"}} >Member List</h5>
      <Divider/>
     
    <Button variant="primary" size="sm" onClick={AddNew}  >
     Add New
      </Button>
      

    </div>
      
    <MemberGrid />
     
    </>
  )
}
